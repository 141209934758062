/* font-family: "SegoeUIRegular"; */
@font-face {
    font-family: "SegoeUIRegular";
    src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot");
    src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUIRegular/SegoeUIRegular.woff") format("woff"),
    url("../fonts/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "SegoeUIBold"; */
@font-face {
    font-family: "SegoeUIBold";
    src: url("../fonts/SegoeUIBold/SegoeUIBold.eot");
    src: url("../fonts/SegoeUIBold/SegoeUIBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUIBold/SegoeUIBold.woff") format("woff"),
    url("../fonts/SegoeUIBold/SegoeUIBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "SegoeUIItalic"; */
@font-face {
    font-family: "SegoeUIItalic";
    src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot");
    src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUIItalic/SegoeUIItalic.woff") format("woff"),
    url("../fonts/SegoeUIItalic/SegoeUIItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "SegoeUILight"; */
@font-face {
    font-family: "SegoeUILight";
    src: url("../fonts/SegoeUILight/SegoeUILight.eot");
    src: url("../fonts/SegoeUILight/SegoeUILight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUILight/SegoeUILight.woff") format("woff"),
    url("../fonts/SegoeUILight/SegoeUILight.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "SegoeUISemiBold"; */
@font-face {
    font-family: "SegoeUISemiBold";
    src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot");
    src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUISemiBold/SegoeUISemiBold.woff") format("woff"),
    url("../fonts/SegoeUISemiBold/SegoeUISemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}