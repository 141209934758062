


#tovaru-open { /* margin-bottom:20px; */ 

.pod-block {  @include pod-block-100; @include respond-to(display) { max-width: 1600px; }; @include clearfix;}

&>.pod-block>.padding { padding: 0px;}

.tovaru-open-left { 

  float:left;
width: calc(100% - 260px); 
  border-right: 260px solid #f1f1f1;

@include respond-to(handhelds) {  float:none; width:100%; border-right: none;}
@include respond-to(wide-handhelds) {  float:none; width:100%; border-right: none; }



 & > .padding {padding: 0px 20px 20px 0px;
@include respond-to(handhelds) { padding: 0px 5px 20px 5px;}
@include respond-to(wide-handhelds) { padding: 0px 5px 20px 5px; }
@include respond-to(small-tablets) { padding: 0px 10px 20px 10px; }
@include respond-to(tablets) { padding: 0px 10px 20px 10px; }
@include respond-to(display) {padding: 0px 10px 20px 10px; }
 } 



} // .tovaru-open-left

.tovaru-open-left-breadcrumb { margin-top: 20px;

.breadcrumb-back {border:1px solid #e1e1e1; display: inline-block; margin-right:20px;

	@include respond-to(handhelds) { display: block; margin:0px 0px 10px 0px;}
@include respond-to(wide-handhelds) { display: block; margin:0px 0px 10px 0px; }

					a {padding:10px 20px 10px 10px; display: block; text-transform: uppercase; font-size: 14px; color:#333; 

	@include respond-to(handhelds) { font-size: 12px;}
@include respond-to(wide-handhelds) { font-size: 12px; }

						img {margin-right:10px; display: inline-block; vertical-align: top;}
						span {display: inline-block; vertical-align: top;}
					}	
} //.breadcrumb-back

.puti { display: inline-block; margin-top:10px;

	@include respond-to(small-tablets) { display: block; margin-top:10px;}

	font-family: "SegoeUILight"; font-size:16px;  margin-bottom:20px; line-height:130%;
@include respond-to(handhelds) { margin:0px 0px 10px 0px; font-size:12px;}
@include respond-to(wide-handhelds) { font-size:12px; }
@include respond-to(small-tablets) { font-size:14px; }
@include respond-to(tablets) { font-size:14px; }
@include respond-to(display) {font-size:14px; }

	a{color:#2f251a; 
		&:hover {color:#d23600}
	}



}

} // .tovaru-open-left-breadcrumb


#category-tab-block {max-width: 100%;


.resp-tabs-list li {font-family: "SegoeUIRegular"; font-size:16px;

@include respond-to(handhelds) { font-size:12px; text-transform: none;}
@include respond-to(wide-handhelds) { font-size:12px; text-transform: none; }
@include respond-to(small-tablets) { font-size:14px; text-transform: none; }
@include respond-to(tablets) { font-size:14px; text-transform: none; }


}	


h2.resp-accordion { font-family: "SegoeUIRegular"; font-weight: normal;
@include respond-to(handhelds) { font-size:14px; text-transform: none;}
@include respond-to(wide-handhelds) { font-size:14px; text-transform: none; }
@include respond-to(small-tablets) { font-size:14px; text-transform: none;  }
@include respond-to(tablets) { font-size:14px; text-transform: none; }
}

.resp-tab-content {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border: none;
    border-top: 1px solid #937c74;
    border-bottom: 1px solid #937c74;

}


@include respond-to(handhelds) { .fotorama-mob {display: block;} .fotorama-comp {display: none;} .br-comp {display: none;}}
@include respond-to(wide-handhelds) { .fotorama-mob {display: block;} .fotorama-comp {display: none;} .br-comp {display: none;} }
@include respond-to(small-tablets) { .fotorama-mob {display: block;} .fotorama-comp {display: none;} }
@include respond-to(tablets) { .fotorama-mob {display: none;} .fotorama-comp {display: block;} }
@include respond-to(display) {.fotorama-mob {display: none;} .fotorama-comp {display: block;} }
@include respond-to(display-big) {.fotorama-mob {display: none;} .fotorama-comp {display: block;} .br-comp {display: none;} }



.category-cart {display:inline-block; width:18.5%; margin:0 0.5% 30px 0.5%; text-align:center; 
		vertical-align:bottom; background:#f5f5f5;
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:48%; }
				@include respond-to(small-tablets) { width:31.5%;}
		a{
			img {max-width:100%; margin-bottom:10px;
				@include respond-to(handhelds) { margin-bottom:10px; }
				}
			h3 { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 18px; text-transform:uppercase; line-height:130%; color:#000000;
			margin-bottom:12px;
				@include respond-to(handhelds) { font-size: 16px; margin-bottom:10px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 16px; }
		}	
			
			} // a

} //.category-cart

.tovaru-cart {display:inline-block; width:20%; margin:0 0.5% 24px 0.5%; font-size: 0; 
		vertical-align:top; border: 16px solid #f0f0f0; background:#f0f0f0;

					/*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/
		
				@include respond-to(handhelds) { width:94%; margin:0 0 40px 0; border: 8px solid #f0f0f0;}
				@include respond-to(wide-handhelds) { width:45%; border: 4px solid #f0f0f0; }
				@include respond-to(small-tablets) { width:94%; border: 4px solid #f0f0f0;}
				@include respond-to(tablets) { width:45%; border: 4px solid #f0f0f0;}
				@media only screen and (min-width: 980px) and (max-width: 1200px) { width:29.5%; border: 8px solid #f0f0f0; }
		
			.tovaru-cart-img {   margin-bottom:16px; display: block;  
					img {max-width:100%; border-bottom:2px solid #fff;}
				@include respond-to(handhelds) { margin-bottom:12px; }

						.tovaru-cart-img-png {display: none;}
						.tovaru-cart-img-jpg {display: block;}

					/*скрываем jpg, показываем png*/
					@include respond-to(display) { 

						background:#f0f0f0; 
						&:hover {background:#fbfbfb; } 

						.tovaru-cart-img-png {display: block;}
						.tovaru-cart-img-jpg {display: none;}
					}


						
			}
				
			.tovaru-cart-title { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px; color:#0f0f0f; line-height:130%; display:block; padding: 10px; 
				width: calc(100% - 20px); text-align: center; 
				@include respond-to(handhelds) { font-size: 14px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 14px; &:hover {background:#fbfbfb;} }	

						
			}

			.cena-line {width:100%; @include clearfix;}

			.cena-old { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 24px; line-height:130%; float:left; width:50%; text-align:center; color:#333; 
			margin-bottom:5px; text-decoration: line-through;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }			
			}


			.cena { font-family: "SegoeUIBold"; font-weight:normal; font-size: 24px; line-height:130%; float:right; width:50%; text-align:center;
			margin-bottom:5px;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }		

			&.cena-centr {width:100%; float:none;}


			}
			
			
		} // .tovaru-cart


} // .category-tab-block




.tovaru-open-right {

  float: left;
  width:260px;
  margin-left: -260px;
  background: #f1f1f1;


@include respond-to(handhelds) {   float: none;   width:100%;  margin-left: 0px;}
@include respond-to(wide-handhelds) {   float: none;  width:100%;  margin-left: 0px; }
 .padding {padding: 38px 30px 20px 30px} 


.nalichie {display: block; text-align: center; font-size: 14px; margin-bottom: 10px;
			font-family: "SegoeUIRegular"; font-weight:normal;

			&.net-nalichia {color:#d23600;}
			&.est-nalichie {color:#2cb425;}
			&.pod-zakaz {color:#033657;}

}


.old-price {font-family: "SegoeUIRegular"; font-weight:normal; font-size: 32px; line-height:130%; 
	text-align: center; text-decoration: line-through; color:#333;}	

.fancy-price {font-family: "SegoeUIRegular"; font-weight:normal; font-size: 36px; line-height:130%; 
	text-align: center;}		

	

.shs-tocart-line {height: 1px; border: none; border-top: 1px solid #bdbdbd; margin-bottom:20px;}

input.tovar-count { width: calc(100% - 32px);
	padding: 10px 15px;
	border: 1px solid #5a372b;
	background: #fff;
	-webkit-appearance: none;
	margin-bottom:10px;
}


.shk-but { width:100%;  background-color: #f1f1f1; /* Green */
    border: 2px solid #2f251a;
    color: #2f251a;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-family: "SegoeUIBold";
	text-transform:uppercase;
	margin-bottom: 20px;
}

.parametru {margin-bottom: 20px;}
.parametru pre {font-size: 13px; font-family: "SegoeUIRegular"; font-weight:normal; line-height: 130%; }
.parametru pre b {margin-top: 20px; width:100%; line-height: 170%;}

.parametru p {font-size: 13px; font-family: "SegoeUIRegular"; font-weight:normal; line-height: 130%; }
.parametru p b {margin-top: 20px; width:100%; line-height: 170%;}

.opis {margin-bottom: 20px; font-size: 12px; font-family: "SegoeUIRegular"; font-weight:normal; line-height: 130%; text-align: justify ;}
.opis h3 {font-size: 13px; font-family: "SegoeUIBold"; font-weight:normal; line-height: 130%;
 margin-bottom: 10px;}


.tovaru-line-open-right {

h3 {font-size: 13px; font-family: "SegoeUIBold"; font-weight:normal; line-height: 130%;
 margin-bottom: 10px; text-align: center;}

.tovaru-cart {width:98%; margin:0 0.5% 24px 0.5%;
		vertical-align:top;

					/*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/
		
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0; }
				@include respond-to(wide-handhelds) { width:50%;  }
		
			.tovaru-cart-img {   margin-bottom:16px; display: block;  
					img {max-width:100%; border-bottom:2px solid #fff;}
				@include respond-to(handhelds) { margin-bottom:12px; }

						.tovaru-cart-img-png {display: none;}
						.tovaru-cart-img-jpg {display: block;}

					/*скрываем jpg, показываем png*/
					@include respond-to(display) { 

						background:#f0f0f0; 
						&:hover {background:#fbfbfb; } 

						.tovaru-cart-img-png {display: block;}
						.tovaru-cart-img-jpg {display: none;}
					}


						
			}
				
			.tovaru-cart-title { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px; color:#0f0f0f; line-height:130%; display:block; padding: 10px; 
				width: calc(100% - 20px); text-align: center; 
				@include respond-to(handhelds) { font-size: 14px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 14px; &:hover {background:#fbfbfb;} }	

						
			}

			.cena-line {width:100%; @include clearfix;}

			.cena-old { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 24px; line-height:130%; float:left; width:50%; text-align:center; color:#333; 
			margin-bottom:5px; text-decoration: line-through;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }			
			}


			.cena { font-family: "SegoeUIBold"; font-weight:normal; font-size: 24px; line-height:130%; float:right; width:50%; text-align:center;
			margin-bottom:5px;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }		

			&.cena-centr {width:100%; float:none;}


			}
			
			
		} // .tovaru-cart






} // .tovaru-open-cart






} // .tovaru-open-right

			
		
} // #tovaru-open 