
  
  
#portfolio {

	.padding {padding-top:20px; padding-bottom:20px;}
	
	.center-line { 
		h1 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; font-weight:normal;
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
		
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
	} //center-line

	
.portfolio-premium {width: 100%; text-align: center; margin-bottom: 20px;

				img {max-width: 100%;}
				.portfolio-premium-small {display: none;}
				.portfolio-premium-big {display: block;}

				// < 479
				@include respond-to(handhelds) {
				.portfolio-premium-small {display: block;}
				.portfolio-premium-big {display: none;}
				 }
				// 480 > 599
				@include respond-to(wide-handhelds) {
				.portfolio-premium-small {display: block;}
				.portfolio-premium-big {display: none;}
				 }
				// 600 > 767
				@include respond-to(small-tablets) {
				.portfolio-premium-small {display: block;}
				.portfolio-premium-big {display: none;}
				 }
				// 768 > 979
				@include respond-to(tablets) { }
				// 980 < 
				@include respond-to(display) { }
				// 1200 < 
				@include respond-to(display-big) { }
}



	

	.portfolio-cart { padding:0px 5px;  margin: 3px; margin-bottom:40px;
	img {max-width:100%; margin-bottom:12px;}
	h3 {font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase; font-weight:normal; margin-bottom:12px; line-height:130%;
			@include respond-to(handhelds) { font-size:14px;}
			@include respond-to(wide-handhelds) { font-size:16px; }

	}

	a.portfolio-more {color:#000; text-decoration: underline; }

	p { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px;  line-height:130%; color:#000000; margin-bottom:20px;}
	



.portfolio-owl {

	a {padding:0px 5px;  margin: 3px;


	}

	img {max-width: 95%;}
}

	.portfolio-owl {display: block;}
	.portfolio-gallery {display: none;}
 
	.portfolio-more-open {display: block; margin-top: 10px;}
	.portfolio-more-close {display: none; margin-top: 10px;}

	&.open {

		.portfolio-gallery {display: block; }
		.portfolio-owl {display: none!important;}
	.portfolio-more-open {display: none; }
	.portfolio-more-close {display: block; }

	}

	.portfolio-gallery a {

		display:inline-block; position: relative; width:18.5%; margin:0 0.5% 0.5% 0.5%; text-align:center; 
		vertical-align:bottom; /* background:#f5f5f5; */
				@include respond-to(handhelds) { width:100%; margin:0 0 20px 0;}
				@include respond-to(wide-handhelds) { width:48%; }
				@include respond-to(small-tablets) { width:31.5%;}

	} 

		.owl-theme .owl-controls .owl-page span{
	background: #000;
}

.portfolio-foto {

	a  span { position: absolute; width: 100%; height: 100%; background: url(../images/video-play-icon.png) center center no-repeat; }

}


	}	//.portfolio-cart
	
	
} // #portfolio































