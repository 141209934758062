@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

/* font-family: "SegoeUIRegular"; */
@font-face {
  font-family: "SegoeUIRegular";
  src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot");
  src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUIRegular/SegoeUIRegular.woff") format("woff"), url("../fonts/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "SegoeUIBold"; */
@font-face {
  font-family: "SegoeUIBold";
  src: url("../fonts/SegoeUIBold/SegoeUIBold.eot");
  src: url("../fonts/SegoeUIBold/SegoeUIBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUIBold/SegoeUIBold.woff") format("woff"), url("../fonts/SegoeUIBold/SegoeUIBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "SegoeUIItalic"; */
@font-face {
  font-family: "SegoeUIItalic";
  src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot");
  src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUIItalic/SegoeUIItalic.woff") format("woff"), url("../fonts/SegoeUIItalic/SegoeUIItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "SegoeUILight"; */
@font-face {
  font-family: "SegoeUILight";
  src: url("../fonts/SegoeUILight/SegoeUILight.eot");
  src: url("../fonts/SegoeUILight/SegoeUILight.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUILight/SegoeUILight.woff") format("woff"), url("../fonts/SegoeUILight/SegoeUILight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "SegoeUISemiBold"; */
@font-face {
  font-family: "SegoeUISemiBold";
  src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot");
  src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUISemiBold/SegoeUISemiBold.woff") format("woff"), url("../fonts/SegoeUISemiBold/SegoeUISemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* $calibri : 'Calibri'; */
/* $gothic : 'franklin-gothic-medium'; */
body {
  font-family: SegoeUILight, sans-serif; }

.clear:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

#header .pod-block {
  margin: 0 auto;
  position: relative;
  box-sizing: content-box;
  background: #ffffff; }
  @media only screen and (max-width: 479px) {
    #header .pod-block {
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #header .pod-block {
      width: 100%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #header .pod-block {
      width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #header .pod-block {
      width: 100%; } }
  @media only screen and (min-width: 980px) {
    #header .pod-block {
      max-width: 1200px; } }
  #header .pod-block .padding {
    height: 185px; }
    @media only screen and (max-width: 479px) {
      #header .pod-block .padding {
        height: auto;
        padding: 10px 10px 0px 10px; }
        #header .pod-block .padding:after {
          content: ".";
          display: block;
          clear: both;
          visibility: hidden;
          line-height: 0;
          height: 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .padding {
        height: auto;
        padding: 10px 10px 0px 10px; }
        #header .pod-block .padding:after {
          content: ".";
          display: block;
          clear: both;
          visibility: hidden;
          line-height: 0;
          height: 0; } }
  #header .pod-block .block-right-adaptive {
    text-align: right; }
  #header .pod-block .header-menu {
    position: absolute;
    top: 13px;
    left: 10px; }
    @media only screen and (max-width: 479px) {
      #header .pod-block .header-menu {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .header-menu {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }
    #header .pod-block .header-menu ul li {
      display: inline-block;
      margin-right: 20px;
      line-height: 130%; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-menu ul li {
          margin-right: 10px; } }
      #header .pod-block .header-menu ul li a {
        font-family: "SegoeUIRegular";
        font-size: 14px;
        color: #262626;
        text-decoration: underline; }
        #header .pod-block .header-menu ul li a:hover {
          text-decoration: none; }
  #header .pod-block .header-logo {
    position: absolute;
    bottom: 18px;
    left: 10px;
    width: 500px; }
    #header .pod-block .header-logo:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    @media only screen and (max-width: 479px) {
      #header .pod-block .header-logo {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px;
        width: auto; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .header-logo {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px;
        width: auto; } }
    #header .pod-block .header-logo a {
      font-family: "SegoeUILight";
      color: #262626; }
    #header .pod-block .header-logo .logo-img {
      width: 84px;
      height: 75px;
      margin-right: 20px;
      float: left; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-logo .logo-img {
          width: 22.5%;
          margin-right: 3.5%; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-logo .logo-img {
          width: 80px;
          height: 70px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-logo .logo-img {
          width: 80px;
          height: 70px; } }
      #header .pod-block .header-logo .logo-img img {
        max-width: 100%;
        max-height: 100%; }
    #header .pod-block .header-logo .logo-text {
      float: left;
      width: 350px; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-logo .logo-text {
          width: 74%; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-logo .logo-text {
          width: 340px; } }
    #header .pod-block .header-logo .logo-big_text {
      font-size: 36px;
      text-transform: uppercase;
      margin: -6px 0px 12px 0px; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-logo .logo-big_text {
          font-size: 24px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-logo .logo-big_text {
          font-size: 28px; } }
    #header .pod-block .header-logo .logo-small_text {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 130%; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-logo .logo-small_text {
          font-size: 12px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-logo .logo-small_text {
          font-size: 12px; } }
  #header .pod-block .header-phone {
    position: absolute;
    top: 13px;
    right: 10px; }
    @media only screen and (max-width: 479px) {
      #header .pod-block .header-phone {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .header-phone {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }
    #header .pod-block .header-phone p {
      font-family: "SegoeUILight";
      font-size: 24px;
      text-transform: uppercase;
      color: #262626;
      line-height: 130%; }
      #header .pod-block .header-phone p a {
        color: #262626; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-phone p {
          font-size: 20px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-phone p {
          font-size: 20px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-phone p {
          font-size: 20px; } }
  #header .pod-block .header-adress {
    position: absolute;
    top: 85px;
    right: 10px; }
    #header .pod-block .header-adress:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    @media only screen and (max-width: 479px) {
      #header .pod-block .header-adress {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .header-adress {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }
    #header .pod-block .header-adress .header-adress_icon {
      width: 23px;
      height: 31px;
      float: left;
      margin-right: 20px; }
      #header .pod-block .header-adress .header-adress_icon img {
        max-width: 100%;
        max-height: 100%; }
    #header .pod-block .header-adress .header-adress_text {
      float: left; }
  #header .pod-block .info {
    position: absolute;
    bottom: 18px;
    right: 10px; }
    #header .pod-block .info:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    #header .pod-block .info .info_block {
      padding: 12px 16px;
      background: #f6f5f3;
      font-family: "SegoeUILight";
      font-size: 12px;
      text-transform: uppercase;
      color: #e90046; }
      #header .pod-block .info .info_block a {
        color: #e90046; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .info .info_block {
          float: left; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .info .info_block {
          float: left; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .info .info_block {
          text-transform: none; } }
    @media only screen and (max-width: 479px) {
      #header .pod-block .info {
        text-transform: none;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .info {
        text-transform: none;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 12px; } }

#navigation {
  background: #2f251a;
  height: 45px; }
  @media only screen and (max-width: 479px) {
    #navigation {
      height: auto; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #navigation {
      height: auto; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #navigation {
      height: auto; } }
  #navigation .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      #navigation .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #navigation .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #navigation .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #navigation .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      #navigation .pod-block {
        max-width: 1200px; } }
    #navigation .pod-block .menu-trigger {
      display: none; }
    #navigation .pod-block .pod-block-left {
      width: 70%;
      float: left; }
      @media only screen and (max-width: 479px) {
        #navigation .pod-block .pod-block-left {
          float: none;
          width: 100%; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #navigation .pod-block .pod-block-left {
          float: none;
          width: 100%; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #navigation .pod-block .pod-block-left {
          float: none;
          width: 100%; } }
      #navigation .pod-block .pod-block-left .padding {
        padding: 0% 1% 0% 0%; }
    #navigation .pod-block .pod-block-right {
      width: 30%;
      float: right; }
      @media only screen and (max-width: 479px) {
        #navigation .pod-block .pod-block-right {
          float: none;
          width: 100%; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #navigation .pod-block .pod-block-right {
          float: none;
          width: 100%; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #navigation .pod-block .pod-block-right {
          float: none;
          width: 100%; } }
      #navigation .pod-block .pod-block-right .padding {
        padding: 2% 1% 1% 1%; }
        #navigation .pod-block .pod-block-right .padding:after {
          content: ".";
          display: block;
          clear: both;
          visibility: hidden;
          line-height: 0;
          height: 0; }
        @media only screen and (max-width: 479px) {
          #navigation .pod-block .pod-block-right .padding {
            padding: 2% 0% 1% 20px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #navigation .pod-block .pod-block-right .padding {
            padding: 2% 0% 1% 20px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #navigation .pod-block .pod-block-right .padding {
            padding: 2% 0% 1% 20px; } }
      #navigation .pod-block .pod-block-right .search-block {
        width: 60%;
        float: left;
        margin-right: 10%; }
        @media only screen and (max-width: 479px) {
          #navigation .pod-block .pod-block-right .search-block {
            margin-right: 5%; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #navigation .pod-block .pod-block-right .search-block {
            width: 50%; } }
      #navigation .pod-block .pod-block-right #search {
        width: 100%;
        height: 30px;
        font-size: 14px;
        border: 1px solid gainsboro;
        box-sizing: border-box;
        padding: 3px; }
      #navigation .pod-block .pod-block-right .shop-block {
        float: left;
        width: 30%;
        margin-top: 5px; }
        @media only screen and (max-width: 479px) {
          #navigation .pod-block .pod-block-right .shop-block {
            width: 35%; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #navigation .pod-block .pod-block-right .shop-block {
            width: 40%; } }
        #navigation .pod-block .pod-block-right .shop-block a {
          color: #fff;
          font-size: 14px; }
          #navigation .pod-block .pod-block-right .shop-block a i {
            font-size: 20px;
            margin-right: 5px; }
          #navigation .pod-block .pod-block-right .shop-block a i.shopping-cart-icon {
            display: inline-block;
            vertical-align: top;
            height: 21px;
            width: 19px;
            background: url("../img/korzina-icon.png") center center no-repeat; }

.menu-link-comp {
  display: block !important; }

.menu-link-mob {
  display: none !important; }

@media only screen and (max-width: 479px) {
  .menu-link-comp {
    display: none !important; }
  .menu-link-mob {
    display: block !important; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .menu-link-comp {
    display: none !important; }
  .menu-link-mob {
    display: block !important; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .menu-link-comp {
    display: none !important; }
  .menu-link-mob {
    display: block !important; } }

#main-baner {
  font-family: "SegoeUILight";
  text-transform: uppercase;
  min-height: 500px;
  background: transparent; }
  @media only screen and (max-width: 479px) {
    #main-baner {
      min-height: 500px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #main-baner {
      min-height: 400px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #main-baner {
      min-height: 400px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #main-baner {
      min-height: 400px; } }
  @media only screen and (max-width: 479px) {
    #main-baner {
      min-height: 500px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #main-baner {
      min-height: 400px; } }
  #main-baner .padding {
    padding-top: 60px; }
  #main-baner .main-baner-header {
    text-align: center;
    color: #fff;
    margin-bottom: 60px;
    text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
    #main-baner .main-baner-header h2 {
      font-size: 48px;
      margin-bottom: 20px; }
    #main-baner .main-baner-header h3 {
      font-size: 24px;
      margin-bottom: 20px; }
    #main-baner .main-baner-header h4 {
      font-size: 30px;
      margin-bottom: 20px; }
    @media only screen and (max-width: 479px) {
      #main-baner .main-baner-header h2 {
        font-size: 36px;
        margin-bottom: 20px; }
      #main-baner .main-baner-header h3 {
        font-size: 18px;
        margin-bottom: 20px; }
      #main-baner .main-baner-header h4 {
        font-size: 24px;
        margin-bottom: 20px; } }
  #main-baner .main-baner-line {
    text-align: center; }
    #main-baner .main-baner-line .main-baner-button {
      display: inline-block;
      margin: 0px 10px 20px 10px;
      padding: 10px 30px;
      background: rgba(71, 52, 33, 0.6);
      border: 1px solid #fff;
      color: #fff;
      font-family: "SegoeUIRegular";
      font-size: 20px; }
      @media only screen and (max-width: 479px) {
        #main-baner .main-baner-line .main-baner-button {
          font-size: 18px; } }
      #main-baner .main-baner-line .main-baner-button:hover {
        background: #473421; }

#popular .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#popular .center-line h2 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-weight: normal;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 479px) {
    #popular .center-line h2 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #popular .center-line h2 {
      font-size: 16px; } }
  #popular .center-line h2 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      #popular .center-line h2 span {
        padding: 0px;
        background: none; } }

#popular .pop-cart-line:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

#popular .pop-cart-line .pop-cart {
  display: inline-block;
  width: 18.5%;
  margin: 0 0.5% 30px 0.5%;
  text-align: center;
  vertical-align: top; }
  @media only screen and (max-width: 479px) {
    #popular .pop-cart-line .pop-cart {
      width: 100%;
      margin: 0 0 40px 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #popular .pop-cart-line .pop-cart {
      width: 48%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #popular .pop-cart-line .pop-cart {
      width: 31.5%; } }
  #popular .pop-cart-line .pop-cart img {
    max-width: 100%;
    margin-bottom: 16px; }
    @media only screen and (max-width: 479px) {
      #popular .pop-cart-line .pop-cart img {
        margin-bottom: 12px; } }
  #popular .pop-cart-line .pop-cart h2 {
    font-family: "SegoeUIBold";
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 130%;
    margin-bottom: 12px; }
    @media only screen and (max-width: 479px) {
      #popular .pop-cart-line .pop-cart h2 {
        font-size: 16px;
        margin-bottom: 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #popular .pop-cart-line .pop-cart h2 {
        font-size: 14px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #popular .pop-cart-line .pop-cart h2 {
        font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #popular .pop-cart-line .pop-cart h2 {
        font-size: 14px; } }
    @media only screen and (min-width: 980px) {
      #popular .pop-cart-line .pop-cart h2 {
        font-size: 16px; } }
  #popular .pop-cart-line .pop-cart p {
    font-family: "SegoeUIRegular";
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 16px; }
    @media only screen and (max-width: 479px) {
      #popular .pop-cart-line .pop-cart p {
        font-size: 14px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #popular .pop-cart-line .pop-cart p {
        font-size: 12px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #popular .pop-cart-line .pop-cart p {
        font-size: 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #popular .pop-cart-line .pop-cart p {
        font-size: 12px; } }
    @media only screen and (min-width: 980px) {
      #popular .pop-cart-line .pop-cart p {
        font-size: 13px; } }
  #popular .pop-cart-line .pop-cart .pop-cart-btn a {
    font-family: "SegoeUILight";
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px 10px;
    color: #fff;
    white-space: nowrap;
    background: #2f251a;
    box-shadow: 5px 5px 0px -2px black; }
    @media only screen and (max-width: 479px) {
      #popular .pop-cart-line .pop-cart .pop-cart-btn a {
        font-size: 14px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #popular .pop-cart-line .pop-cart .pop-cart-btn a {
        font-size: 12px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #popular .pop-cart-line .pop-cart .pop-cart-btn a {
        font-size: 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #popular .pop-cart-line .pop-cart .pop-cart-btn a {
        font-size: 12px; } }
    @media only screen and (min-width: 980px) {
      #popular .pop-cart-line .pop-cart .pop-cart-btn a {
        font-size: 13px; } }
    #popular .pop-cart-line .pop-cart .pop-cart-btn a:hover {
      box-shadow: none; }

#category-tab .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#category-tab .category-cart {
  display: inline-block;
  width: 18.5%;
  margin: 0 0.5% 30px 0.5%;
  text-align: center;
  vertical-align: bottom;
  background: #f5f5f5; }
  @media only screen and (max-width: 479px) {
    #category-tab .category-cart {
      width: 100%;
      margin: 0 0 40px 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #category-tab .category-cart {
      width: 48%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #category-tab .category-cart {
      width: 31.5%; } }
  #category-tab .category-cart a img {
    max-width: 100%;
    margin-bottom: 10px; }
    @media only screen and (max-width: 479px) {
      #category-tab .category-cart a img {
        margin-bottom: 10px; } }
  #category-tab .category-cart a h3 {
    font-family: "SegoeUIRegular";
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 130%;
    color: #000000;
    margin-bottom: 12px; }
    @media only screen and (max-width: 479px) {
      #category-tab .category-cart a h3 {
        font-size: 16px;
        margin-bottom: 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-tab .category-cart a h3 {
        font-size: 14px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-tab .category-cart a h3 {
        font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-tab .category-cart a h3 {
        font-size: 14px; } }
    @media only screen and (min-width: 980px) {
      #category-tab .category-cart a h3 {
        font-size: 16px; } }

#otzuvu-baner {
  font-family: "SegoeUILight";
  text-transform: uppercase;
  min-height: 500px;
  background: transparent; }
  @media only screen and (max-width: 479px) {
    #otzuvu-baner {
      min-height: auto; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #otzuvu-baner {
      min-height: 400px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #otzuvu-baner {
      min-height: 400px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #otzuvu-baner {
      min-height: 400px; } }
  @media only screen and (max-width: 479px) {
    #otzuvu-baner {
      min-height: 500px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #otzuvu-baner {
      min-height: 400px; } }
  #otzuvu-baner .padding {
    padding-top: 60px; }
  #otzuvu-baner .otzuvu-baner-header {
    text-align: center;
    color: #fff;
    margin-bottom: 60px;
    text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
    #otzuvu-baner .otzuvu-baner-header h2 {
      font-size: 48px;
      margin-bottom: 20px; }
    #otzuvu-baner .otzuvu-baner-header h3 {
      font-size: 24px;
      margin-bottom: 20px; }
    #otzuvu-baner .otzuvu-baner-header h4 {
      font-size: 30px;
      margin-bottom: 20px; }
    @media only screen and (max-width: 479px) {
      #otzuvu-baner .otzuvu-baner-header h2 {
        font-size: 36px;
        margin-bottom: 20px; }
      #otzuvu-baner .otzuvu-baner-header h3 {
        font-size: 18px;
        margin-bottom: 20px; }
      #otzuvu-baner .otzuvu-baner-header h4 {
        font-size: 24px;
        margin-bottom: 20px; } }
  #otzuvu-baner .owl-otzuvu-cart {
    text-align: center;
    font-family: "SegoeUILight";
    font-size: 18px;
    color: #fff; }
    @media only screen and (max-width: 479px) {
      #otzuvu-baner .owl-otzuvu-cart {
        font-size: 16px; } }
    #otzuvu-baner .owl-otzuvu-cart p {
      max-width: 600px;
      display: block;
      margin: 0 auto 20px;
      line-height: 130%;
      padding: 0px 10px;
      text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
  #otzuvu-baner .otzuvu-baner-line {
    text-align: center;
    margin-top: 20px; }
    #otzuvu-baner .otzuvu-baner-line .otzuvu-baner-button {
      display: inline-block;
      margin: 0px 10px 20px 10px;
      padding: 10px 30px;
      background: rgba(71, 52, 33, 0.6);
      border: 1px solid #fff;
      color: #fff;
      font-family: "SegoeUIRegular";
      font-size: 20px; }
      @media only screen and (max-width: 479px) {
        #otzuvu-baner .otzuvu-baner-line .otzuvu-baner-button {
          font-size: 18px; } }
      #otzuvu-baner .otzuvu-baner-line .otzuvu-baner-button:hover {
        background: #473421; }

#news .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#news .center-line h2 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: normal; }
  @media only screen and (max-width: 479px) {
    #news .center-line h2 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #news .center-line h2 {
      font-size: 16px; } }
  #news .center-line h2 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      #news .center-line h2 span {
        padding: 0px;
        background: none; } }

#news .news-cart {
  padding: 0px 5px;
  margin: 3px; }
  #news .news-cart img {
    max-width: 100%;
    margin-bottom: 12px; }
  #news .news-cart h3 {
    text-align: center;
    font-family: "SegoeUIBold";
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 12px;
    line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #news .news-cart h3 {
        font-size: 14px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #news .news-cart h3 {
        font-size: 16px; } }

#news p {
  text-align: center;
  font-family: "SegoeUIRegular";
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #000000; }

#news .owl-theme .owl-controls .owl-page span {
  background: #000; }

#text .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#text .center-line h1 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: normal; }
  @media only screen and (max-width: 479px) {
    #text .center-line h1 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #text .center-line h1 {
      font-size: 16px; } }
  #text .center-line h1 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      #text .center-line h1 span {
        padding: 0px;
        background: none; } }

#text .text-col-line {
  padding: 0px 10px; }
  #text .text-col-line .text-col-31 {
    display: inline-block;
    width: 31.5%;
    margin: 0 0.5% 18px 0.5%;
    text-align: justify;
    vertical-align: top; }
    @media only screen and (max-width: 479px) {
      #text .text-col-line .text-col-31 {
        width: 100%;
        margin: 0 0 40px 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #text .text-col-line .text-col-31 {
        width: 96%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #text .text-col-line .text-col-31 {
        width: 96%; } }
    #text .text-col-line .text-col-31 img {
      max-width: 100%;
      margin-bottom: 18px; }
    #text .text-col-line .text-col-31 h2 {
      font-family: "SegoeUIBold";
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 18px;
      line-height: 130%; }
    #text .text-col-line .text-col-31 p {
      font-family: "SegoeUIRegular";
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 16px;
      line-height: 130%; }
  #text .text-col-line .text-col-47 {
    display: inline-block;
    width: 47.5%;
    margin: 0 0.5% 18px 0.5%;
    vertical-align: top; }
    @media only screen and (max-width: 479px) {
      #text .text-col-line .text-col-47 {
        width: 100%;
        margin: 0 0 40px 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #text .text-col-line .text-col-47 {
        width: 96%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #text .text-col-line .text-col-47 {
        width: 96%; } }
    #text .text-col-line .text-col-47 img {
      max-width: 100%;
      margin-bottom: 18px; }
    #text .text-col-line .text-col-47 h1 {
      text-align: left;
      font-family: "SegoeUIBold";
      font-size: 22px;
      font-weight: normal;
      margin-bottom: 18px; }
    #text .text-col-line .text-col-47 h2 {
      font-family: "SegoeUIBold";
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 18px;
      line-height: 130%; }
    #text .text-col-line .text-col-47 p, #text .text-col-line .text-col-47 li {
      font-family: "SegoeUIRegular";
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 16px;
      line-height: 130%;
      text-align: justify; }
    #text .text-col-line .text-col-47 ol, #text .text-col-line .text-col-47 ul {
      margin-left: 20px; }
    #text .text-col-line .text-col-47 ol li {
      list-style-type: decimal;
      text-align: justify; }
    #text .text-col-line .text-col-47 ul li {
      list-style-type: disc;
      text-align: justify; }

#text .text-col-line-3 {
  padding: 0px 10px;
  width: calc(100% - 20px);
  /*указываем общую ширину блока с колонками*/
  text-align: justify;
  /*выравнивание текста внутри колонок*/
  column-count: 3;
  /*количество колонок, на которое хотим разбить текст*/
  -moz-column-count: 3;
  /*для мозилы*/
  -webkit-column-count: 3;
  /*для webkit браузеров*/
  column-gap: 20px;
  /*отступ между колонками*/
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  /* column-rule: 1px solid #000; если требуется по дизайну, разделяем колонки линией
-moz-column-rule: 1px solid #000;
-webkit-column-rule: 1px solid #000;*/ }
  @media only screen and (max-width: 479px) {
    #text .text-col-line-3 {
      column-count: 1;
      margin: 0 0 40px 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #text .text-col-line-3 {
      column-count: 1; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #text .text-col-line-3 {
      column-count: 2; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #text .text-col-line-3 {
      column-count: 2; } }
  #text .text-col-line-3 img {
    max-width: 100%;
    margin-bottom: 18px; }
  #text .text-col-line-3 h2 {
    font-family: "SegoeUIBold";
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 18px;
    line-height: 130%; }
  #text .text-col-line-3 p {
    font-family: "SegoeUIRegular";
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 16px;
    line-height: 130%; }

#stati .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#stati .center-line h1 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: normal; }
  @media only screen and (max-width: 479px) {
    #stati .center-line h1 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #stati .center-line h1 {
      font-size: 16px; } }
  #stati .center-line h1 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      #stati .center-line h1 span {
        padding: 0px;
        background: none; } }

#stati .stati-line-block {
  width: calc(100% - 20px);
  margin-bottom: 20px;
  padding: 0px 10px; }
  #stati .stati-line-block .stati-img-left {
    width: 148px;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px; }
    @media only screen and (max-width: 479px) {
      #stati .stati-line-block .stati-img-left {
        width: 100%;
        float: none;
        margin-right: 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #stati .stati-line-block .stati-img-left {
        width: 100%;
        float: none;
        margin-right: 0; } }
  #stati .stati-line-block .stati-text-right {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 180px); }
    @media only screen and (max-width: 479px) {
      #stati .stati-line-block .stati-text-right {
        width: 100%;
        float: none;
        margin-right: 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #stati .stati-line-block .stati-text-right {
        width: 100%;
        float: none;
        margin-right: 0; } }
  #stati .stati-line-block a {
    color: #000; }
    #stati .stati-line-block a:hover {
      text-decoration: underline; }
  #stati .stati-line-block img {
    max-width: 100%; }
  #stati .stati-line-block h2 {
    font-family: "SegoeUIBold";
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 18px;
    line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #stati .stati-line-block h2 {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #stati .stati-line-block h2 {
        margin-bottom: 10px; } }
  #stati .stati-line-block p {
    font-family: "SegoeUIRegular";
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 16px;
    line-height: 130%; }

#kontaktu:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

#kontaktu .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#kontaktu .center-line h1 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: normal; }
  @media only screen and (max-width: 479px) {
    #kontaktu .center-line h1 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #kontaktu .center-line h1 {
      font-size: 16px; } }
  #kontaktu .center-line h1 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      #kontaktu .center-line h1 span {
        padding: 0px;
        background: none; } }

#kontaktu .map-left {
  width: 65%;
  margin-right: 2%;
  float: left; }
  @media only screen and (max-width: 479px) {
    #kontaktu .map-left {
      width: 100%;
      float: none;
      margin-right: 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #kontaktu .map-left {
      width: 100%;
      float: none;
      margin-right: 0; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #kontaktu .map-left {
      width: 48%; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #kontaktu .map-left {
      width: 48%; } }

#kontaktu .kontaktu-text-right {
  width: 33%;
  float: right; }
  @media only screen and (max-width: 479px) {
    #kontaktu .kontaktu-text-right {
      width: calc(100% - 20px);
      float: none;
      padding: 10px;
      margin-bottom: 10px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #kontaktu .kontaktu-text-right {
      width: calc(100% - 20px);
      float: none;
      padding: 10px;
      margin-bottom: 10px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #kontaktu .kontaktu-text-right {
      width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #kontaktu .kontaktu-text-right {
      width: 50%; } }

#kontaktu .kontaktu-text-line {
  font-family: "SegoeUIRegular";
  font-size: 14px;
  color: #171717;
  margin-bottom: 20px; }
  @media only screen and (max-width: 479px) {
    #kontaktu .kontaktu-text-line {
      margin-bottom: 10px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #kontaktu .kontaktu-text-line {
      margin-bottom: 10px; } }
  #kontaktu .kontaktu-text-line .kontaktu-icon {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
    width: 55px; }
    #kontaktu .kontaktu-text-line .kontaktu-icon img {
      max-width: 100%; }
  #kontaktu .kontaktu-text-line .kontaktu-text-info {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 70px); }
    #kontaktu .kontaktu-text-line .kontaktu-text-info p {
      line-height: 130%; }
    #kontaktu .kontaktu-text-line .kontaktu-text-info a {
      color: #171717;
      text-decoration: underline; }
      #kontaktu .kontaktu-text-line .kontaktu-text-info a:hover {
        text-decoration: none; }
    #kontaktu .kontaktu-text-line .kontaktu-text-info.tel {
      font-size: 24px;
      vertical-align: top; }
    #kontaktu .kontaktu-text-line .kontaktu-text-info.mail {
      font-size: 18px;
      padding-top: 15px; }

#breadcrumb {
  font-family: "SegoeUILight";
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 130%; }
  @media only screen and (max-width: 479px) {
    #breadcrumb {
      margin: 0px 0px 10px 0px;
      font-size: 12px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #breadcrumb {
      font-size: 12px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #breadcrumb {
      font-size: 14px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #breadcrumb {
      font-size: 14px; } }
  @media only screen and (min-width: 980px) {
    #breadcrumb {
      font-size: 14px; } }
  #breadcrumb a {
    color: #2f251a; }
    #breadcrumb a:hover {
      color: #d23600; }
  #breadcrumb .padding {
    padding: 20px 10px 0px 10px; }
  #breadcrumb .breadcrumb-back {
    border: 1px solid #e1e1e1;
    display: inline-block;
    margin-right: 20px; }
    @media only screen and (max-width: 479px) {
      #breadcrumb .breadcrumb-back {
        display: block;
        margin: 0px 0px 10px 0px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #breadcrumb .breadcrumb-back {
        display: block;
        margin: 0px 0px 10px 0px; } }
    #breadcrumb .breadcrumb-back a {
      padding: 10px 20px 10px 10px;
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      color: #333; }
      @media only screen and (max-width: 479px) {
        #breadcrumb .breadcrumb-back a {
          font-size: 12px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #breadcrumb .breadcrumb-back a {
          font-size: 12px; } }
      #breadcrumb .breadcrumb-back a img {
        margin-right: 10px;
        display: inline-block;
        vertical-align: top; }
      #breadcrumb .breadcrumb-back a span {
        display: inline-block;
        vertical-align: top; }
  #breadcrumb .puti {
    display: inline-block;
    margin-top: 10px;
    font-family: "SegoeUILight";
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 130%; }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #breadcrumb .puti {
        display: block;
        margin-top: 10px; } }
    @media only screen and (max-width: 479px) {
      #breadcrumb .puti {
        margin: 0px 0px 10px 0px;
        font-size: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #breadcrumb .puti {
        font-size: 12px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #breadcrumb .puti {
        font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #breadcrumb .puti {
        font-size: 14px; } }
    @media only screen and (min-width: 980px) {
      #breadcrumb .puti {
        font-size: 14px; } }
    #breadcrumb .puti a {
      color: #2f251a; }
      #breadcrumb .puti a:hover {
        color: #d23600; }

#category-text {
  margin-bottom: 20px; }
  #category-text .padding {
    padding: 0px 10px 0px 10px; }
  #category-text h1 {
    font-family: "SegoeUILight";
    font-size: 28px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 18px;
    line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #category-text h1 {
        margin: 0px 0px 10px 0px;
        font-size: 16px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-text h1 {
        font-size: 16px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-text h1 {
        font-size: 18px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-text h1 {
        font-size: 20px; } }
    @media only screen and (min-width: 980px) {
      #category-text h1 {
        font-size: 24px; } }
  #category-text h2 {
    font-family: "SegoeUILight";
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 18px;
    line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #category-text h2 {
        margin: 0px 0px 10px 0px;
        font-size: 13px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-text h2 {
        font-size: 14px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-text h2 {
        font-size: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-text h2 {
        font-size: 16px; } }
    @media only screen and (min-width: 980px) {
      #category-text h2 {
        font-size: 17px; } }
  #category-text p {
    font-family: "SegoeUIRegular";
    font-size: 16px;
    margin-bottom: 18px;
    line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #category-text p {
        margin: 0px 0px 8px 0px;
        font-size: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-text p {
        font-size: 12px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-text p {
        font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-text p {
        font-size: 14px; } }
    @media only screen and (min-width: 980px) {
      #category-text p {
        font-size: 14px; } }
  #category-text a {
    color: #603426;
    text-decoration: underline; }
  #category-text a:visited {
    color: #6E615D; }
  #category-text a:hover {
    text-decoration: none; }
  #category-text a:active {
    color: #937C74; }
  #category-text .line-premium:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  #category-text .line-premium .line-premium-img {
    float: left;
    width: 5%;
    text-align: center; }
    @media only screen and (max-width: 479px) {
      #category-text .line-premium .line-premium-img {
        display: none; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-text .line-premium .line-premium-img {
        display: none; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-text .line-premium .line-premium-img {
        width: 20%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-text .line-premium .line-premium-img {
        width: 20%; } }
    @media only screen and (min-width: 980px) {
      #category-text .line-premium .line-premium-img {
        width: 10%; } }
    #category-text .line-premium .line-premium-img img {
      max-width: 100%; }
  #category-text .line-premium ul {
    float: left;
    width: 95%; }
    @media only screen and (max-width: 479px) {
      #category-text .line-premium ul {
        float: none; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-text .line-premium ul {
        float: none; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-text .line-premium ul {
        width: 80%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-text .line-premium ul {
        width: 80%; } }
    @media only screen and (min-width: 980px) {
      #category-text .line-premium ul {
        width: 90%; } }
  #category-text .line-premium ul li {
    font-family: "SegoeUIRegular";
    font-size: 16px;
    line-height: 130%;
    margin: 0px 0px 12px 10px; }
    @media only screen and (max-width: 479px) {
      #category-text .line-premium ul li {
        margin: 0px 0px 8px 0px;
        font-size: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #category-text .line-premium ul li {
        font-size: 12px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #category-text .line-premium ul li {
        font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #category-text .line-premium ul li {
        font-size: 14px; } }
    @media only screen and (min-width: 980px) {
      #category-text .line-premium ul li {
        font-size: 14px; } }

#tovaru {
  margin-bottom: 20px; }
  @media only screen and (min-width: 980px) {
    #tovaru .pod-block {
      max-width: 1600px; } }
  #tovaru .padding {
    padding: 0px 10px 0px 10px; }
  #tovaru .tovaru-cart {
    display: inline-block;
    width: 20.5%;
    margin: 0 0.5% 24px 0.5%;
    vertical-align: top;
    border: 16px solid #f0f0f0;
    background: #f0f0f0;
    /*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/ }
    @media only screen and (max-width: 479px) {
      #tovaru .tovaru-cart {
        width: 94%;
        margin: 0 0 40px 0;
        border: 8px solid #f0f0f0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #tovaru .tovaru-cart {
        width: 44%;
        border: 4px solid #f0f0f0; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #tovaru .tovaru-cart {
        width: 28.5%;
        border: 8px solid #f0f0f0; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #tovaru .tovaru-cart {
        width: 29.5%;
        border: 8px solid #f0f0f0; } }
    @media only screen and (min-width: 980px) and (max-width: 1200px) {
      #tovaru .tovaru-cart {
        border: 8px solid #f0f0f0; } }
    #tovaru .tovaru-cart .tovaru-cart-img {
      margin-bottom: 16px;
      display: block;
      /*скрываем jpg, показываем png*/ }
      #tovaru .tovaru-cart .tovaru-cart-img img {
        max-width: 100%;
        border-bottom: 2px solid #fff; }
      @media only screen and (max-width: 479px) {
        #tovaru .tovaru-cart .tovaru-cart-img {
          margin-bottom: 12px; } }
      #tovaru .tovaru-cart .tovaru-cart-img .tovaru-cart-img-png {
        display: none; }
      #tovaru .tovaru-cart .tovaru-cart-img .tovaru-cart-img-jpg {
        display: block; }
      @media only screen and (min-width: 980px) {
        #tovaru .tovaru-cart .tovaru-cart-img {
          background: #f0f0f0; }
          #tovaru .tovaru-cart .tovaru-cart-img:hover {
            background: #fbfbfb; }
          #tovaru .tovaru-cart .tovaru-cart-img .tovaru-cart-img-png {
            display: block; }
          #tovaru .tovaru-cart .tovaru-cart-img .tovaru-cart-img-jpg {
            display: none; } }
    #tovaru .tovaru-cart .pattern-line {
      margin-top: -26px;
      margin-bottom: 6px;
      margin-left: 25px; }
      #tovaru .tovaru-cart .pattern-line .pattern {
        display: inline-block;
        width: 20px;
        margin-right: 2px; }
        #tovaru .tovaru-cart .pattern-line .pattern img {
          max-width: 100%; }
    #tovaru .tovaru-cart .tovaru-cart-title {
      font-family: "SegoeUIRegular";
      font-weight: normal;
      font-size: 14px;
      color: #0f0f0f;
      line-height: 130%;
      display: block;
      padding: 10px;
      width: calc(100% - 20px);
      text-align: center; }
      @media only screen and (max-width: 479px) {
        #tovaru .tovaru-cart .tovaru-cart-title {
          font-size: 14px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru .tovaru-cart .tovaru-cart-title {
          font-size: 14px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru .tovaru-cart .tovaru-cart-title {
          font-size: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru .tovaru-cart .tovaru-cart-title {
          font-size: 14px; } }
      @media only screen and (min-width: 980px) {
        #tovaru .tovaru-cart .tovaru-cart-title {
          font-size: 14px; }
          #tovaru .tovaru-cart .tovaru-cart-title:hover {
            background: #fbfbfb; } }
      #tovaru .tovaru-cart .tovaru-cart-title .net-nalichia {
        color: #d23600; }
      #tovaru .tovaru-cart .tovaru-cart-title .est-nalichie {
        color: #2cb425; }
      #tovaru .tovaru-cart .tovaru-cart-title .pod-zakaz {
        color: #033657; }
    #tovaru .tovaru-cart .cena-line {
      width: 100%; }
      #tovaru .tovaru-cart .cena-line:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0; }
    #tovaru .tovaru-cart .cena-old {
      font-family: "SegoeUIRegular";
      font-weight: normal;
      font-size: 24px;
      line-height: 130%;
      float: left;
      width: 50%;
      text-align: center;
      color: #333;
      margin-bottom: 5px;
      text-decoration: line-through; }
      @media only screen and (max-width: 479px) {
        #tovaru .tovaru-cart .cena-old {
          font-size: 16px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru .tovaru-cart .cena-old {
          font-size: 18px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru .tovaru-cart .cena-old {
          font-size: 18px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru .tovaru-cart .cena-old {
          font-size: 18px; } }
      @media only screen and (min-width: 980px) {
        #tovaru .tovaru-cart .cena-old {
          font-size: 20px; } }
    #tovaru .tovaru-cart .cena {
      font-family: "SegoeUIBold";
      font-weight: normal;
      font-size: 24px;
      line-height: 130%;
      float: right;
      width: 50%;
      text-align: center;
      margin-bottom: 5px; }
      @media only screen and (max-width: 479px) {
        #tovaru .tovaru-cart .cena {
          font-size: 16px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru .tovaru-cart .cena {
          font-size: 18px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru .tovaru-cart .cena {
          font-size: 18px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru .tovaru-cart .cena {
          font-size: 18px; } }
      @media only screen and (min-width: 980px) {
        #tovaru .tovaru-cart .cena {
          font-size: 20px; } }
      #tovaru .tovaru-cart .cena.cena-centr {
        width: 100%;
        float: none; }

#tovaru-open {
  /* margin-bottom:20px; */ }
  #tovaru-open .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      #tovaru-open .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #tovaru-open .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #tovaru-open .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #tovaru-open .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      #tovaru-open .pod-block {
        max-width: 1200px; } }
    @media only screen and (min-width: 980px) {
      #tovaru-open .pod-block {
        max-width: 1600px; } }
    #tovaru-open .pod-block:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  #tovaru-open > .pod-block > .padding {
    padding: 0px; }
  #tovaru-open .tovaru-open-left {
    float: left;
    width: calc(100% - 260px);
    border-right: 260px solid #f1f1f1; }
    @media only screen and (max-width: 479px) {
      #tovaru-open .tovaru-open-left {
        float: none;
        width: 100%;
        border-right: none; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #tovaru-open .tovaru-open-left {
        float: none;
        width: 100%;
        border-right: none; } }
    #tovaru-open .tovaru-open-left > .padding {
      padding: 0px 20px 20px 0px; }
      @media only screen and (max-width: 479px) {
        #tovaru-open .tovaru-open-left > .padding {
          padding: 0px 5px 20px 5px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open .tovaru-open-left > .padding {
          padding: 0px 5px 20px 5px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open .tovaru-open-left > .padding {
          padding: 0px 10px 20px 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru-open .tovaru-open-left > .padding {
          padding: 0px 10px 20px 10px; } }
      @media only screen and (min-width: 980px) {
        #tovaru-open .tovaru-open-left > .padding {
          padding: 0px 10px 20px 10px; } }
  #tovaru-open .tovaru-open-left-breadcrumb {
    margin-top: 20px; }
    #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back {
      border: 1px solid #e1e1e1;
      display: inline-block;
      margin-right: 20px; }
      @media only screen and (max-width: 479px) {
        #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back {
          display: block;
          margin: 0px 0px 10px 0px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back {
          display: block;
          margin: 0px 0px 10px 0px; } }
      #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back a {
        padding: 10px 20px 10px 10px;
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        color: #333; }
        @media only screen and (max-width: 479px) {
          #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back a {
            font-size: 12px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back a {
            font-size: 12px; } }
        #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back a img {
          margin-right: 10px;
          display: inline-block;
          vertical-align: top; }
        #tovaru-open .tovaru-open-left-breadcrumb .breadcrumb-back a span {
          display: inline-block;
          vertical-align: top; }
    #tovaru-open .tovaru-open-left-breadcrumb .puti {
      display: inline-block;
      margin-top: 10px;
      font-family: "SegoeUILight";
      font-size: 16px;
      margin-bottom: 20px;
      line-height: 130%; }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open .tovaru-open-left-breadcrumb .puti {
          display: block;
          margin-top: 10px; } }
      @media only screen and (max-width: 479px) {
        #tovaru-open .tovaru-open-left-breadcrumb .puti {
          margin: 0px 0px 10px 0px;
          font-size: 12px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open .tovaru-open-left-breadcrumb .puti {
          font-size: 12px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open .tovaru-open-left-breadcrumb .puti {
          font-size: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru-open .tovaru-open-left-breadcrumb .puti {
          font-size: 14px; } }
      @media only screen and (min-width: 980px) {
        #tovaru-open .tovaru-open-left-breadcrumb .puti {
          font-size: 14px; } }
      #tovaru-open .tovaru-open-left-breadcrumb .puti a {
        color: #2f251a; }
        #tovaru-open .tovaru-open-left-breadcrumb .puti a:hover {
          color: #d23600; }
  #tovaru-open #category-tab-block {
    max-width: 100%; }
    #tovaru-open #category-tab-block .resp-tabs-list li {
      font-family: "SegoeUIRegular";
      font-size: 16px; }
      @media only screen and (max-width: 479px) {
        #tovaru-open #category-tab-block .resp-tabs-list li {
          font-size: 12px;
          text-transform: none; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open #category-tab-block .resp-tabs-list li {
          font-size: 12px;
          text-transform: none; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open #category-tab-block .resp-tabs-list li {
          font-size: 14px;
          text-transform: none; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru-open #category-tab-block .resp-tabs-list li {
          font-size: 14px;
          text-transform: none; } }
    #tovaru-open #category-tab-block h2.resp-accordion {
      font-family: "SegoeUIRegular";
      font-weight: normal; }
      @media only screen and (max-width: 479px) {
        #tovaru-open #category-tab-block h2.resp-accordion {
          font-size: 14px;
          text-transform: none; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open #category-tab-block h2.resp-accordion {
          font-size: 14px;
          text-transform: none; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open #category-tab-block h2.resp-accordion {
          font-size: 14px;
          text-transform: none; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru-open #category-tab-block h2.resp-accordion {
          font-size: 14px;
          text-transform: none; } }
    #tovaru-open #category-tab-block .resp-tab-content {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border: none;
      border-top: 1px solid #937c74;
      border-bottom: 1px solid #937c74; }
    @media only screen and (max-width: 479px) {
      #tovaru-open #category-tab-block .fotorama-mob {
        display: block; }
      #tovaru-open #category-tab-block .fotorama-comp {
        display: none; }
      #tovaru-open #category-tab-block .br-comp {
        display: none; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #tovaru-open #category-tab-block .fotorama-mob {
        display: block; }
      #tovaru-open #category-tab-block .fotorama-comp {
        display: none; }
      #tovaru-open #category-tab-block .br-comp {
        display: none; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #tovaru-open #category-tab-block .fotorama-mob {
        display: block; }
      #tovaru-open #category-tab-block .fotorama-comp {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #tovaru-open #category-tab-block .fotorama-mob {
        display: none; }
      #tovaru-open #category-tab-block .fotorama-comp {
        display: block; } }
    @media only screen and (min-width: 980px) {
      #tovaru-open #category-tab-block .fotorama-mob {
        display: none; }
      #tovaru-open #category-tab-block .fotorama-comp {
        display: block; } }
    @media only screen and (min-width: 1200px) {
      #tovaru-open #category-tab-block .fotorama-mob {
        display: none; }
      #tovaru-open #category-tab-block .fotorama-comp {
        display: block; }
      #tovaru-open #category-tab-block .br-comp {
        display: none; } }
    #tovaru-open #category-tab-block .category-cart {
      display: inline-block;
      width: 18.5%;
      margin: 0 0.5% 30px 0.5%;
      text-align: center;
      vertical-align: bottom;
      background: #f5f5f5; }
      @media only screen and (max-width: 479px) {
        #tovaru-open #category-tab-block .category-cart {
          width: 100%;
          margin: 0 0 40px 0; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open #category-tab-block .category-cart {
          width: 48%; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open #category-tab-block .category-cart {
          width: 31.5%; } }
      #tovaru-open #category-tab-block .category-cart a img {
        max-width: 100%;
        margin-bottom: 10px; }
        @media only screen and (max-width: 479px) {
          #tovaru-open #category-tab-block .category-cart a img {
            margin-bottom: 10px; } }
      #tovaru-open #category-tab-block .category-cart a h3 {
        font-family: "SegoeUIRegular";
        font-weight: normal;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 130%;
        color: #000000;
        margin-bottom: 12px; }
        @media only screen and (max-width: 479px) {
          #tovaru-open #category-tab-block .category-cart a h3 {
            font-size: 16px;
            margin-bottom: 10px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open #category-tab-block .category-cart a h3 {
            font-size: 14px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open #category-tab-block .category-cart a h3 {
            font-size: 14px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open #category-tab-block .category-cart a h3 {
            font-size: 14px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open #category-tab-block .category-cart a h3 {
            font-size: 16px; } }
    #tovaru-open #category-tab-block .tovaru-cart {
      display: inline-block;
      width: 20%;
      margin: 0 0.5% 24px 0.5%;
      font-size: 0;
      vertical-align: top;
      border: 16px solid #f0f0f0;
      background: #f0f0f0;
      /*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/ }
      @media only screen and (max-width: 479px) {
        #tovaru-open #category-tab-block .tovaru-cart {
          width: 94%;
          margin: 0 0 40px 0;
          border: 8px solid #f0f0f0; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open #category-tab-block .tovaru-cart {
          width: 45%;
          border: 4px solid #f0f0f0; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #tovaru-open #category-tab-block .tovaru-cart {
          width: 94%;
          border: 4px solid #f0f0f0; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #tovaru-open #category-tab-block .tovaru-cart {
          width: 45%;
          border: 4px solid #f0f0f0; } }
      @media only screen and (min-width: 980px) and (max-width: 1200px) {
        #tovaru-open #category-tab-block .tovaru-cart {
          width: 29.5%;
          border: 8px solid #f0f0f0; } }
      #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img {
        margin-bottom: 16px;
        display: block;
        /*скрываем jpg, показываем png*/ }
        #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img img {
          max-width: 100%;
          border-bottom: 2px solid #fff; }
        @media only screen and (max-width: 479px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img {
            margin-bottom: 12px; } }
        #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img .tovaru-cart-img-png {
          display: none; }
        #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img .tovaru-cart-img-jpg {
          display: block; }
        @media only screen and (min-width: 980px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img {
            background: #f0f0f0; }
            #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img:hover {
              background: #fbfbfb; }
            #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img .tovaru-cart-img-png {
              display: block; }
            #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-img .tovaru-cart-img-jpg {
              display: none; } }
      #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title {
        font-family: "SegoeUIRegular";
        font-weight: normal;
        font-size: 14px;
        color: #0f0f0f;
        line-height: 130%;
        display: block;
        padding: 10px;
        width: calc(100% - 20px);
        text-align: center; }
        @media only screen and (max-width: 479px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title {
            font-size: 14px; }
            #tovaru-open #category-tab-block .tovaru-cart .tovaru-cart-title:hover {
              background: #fbfbfb; } }
      #tovaru-open #category-tab-block .tovaru-cart .cena-line {
        width: 100%; }
        #tovaru-open #category-tab-block .tovaru-cart .cena-line:after {
          content: ".";
          display: block;
          clear: both;
          visibility: hidden;
          line-height: 0;
          height: 0; }
      #tovaru-open #category-tab-block .tovaru-cart .cena-old {
        font-family: "SegoeUIRegular";
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        float: left;
        width: 50%;
        text-align: center;
        color: #333;
        margin-bottom: 5px;
        text-decoration: line-through; }
        @media only screen and (max-width: 479px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena-old {
            font-size: 16px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena-old {
            font-size: 18px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena-old {
            font-size: 18px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena-old {
            font-size: 18px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena-old {
            font-size: 20px; } }
      #tovaru-open #category-tab-block .tovaru-cart .cena {
        font-family: "SegoeUIBold";
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        float: right;
        width: 50%;
        text-align: center;
        margin-bottom: 5px; }
        @media only screen and (max-width: 479px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena {
            font-size: 16px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena {
            font-size: 18px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena {
            font-size: 18px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena {
            font-size: 18px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open #category-tab-block .tovaru-cart .cena {
            font-size: 20px; } }
        #tovaru-open #category-tab-block .tovaru-cart .cena.cena-centr {
          width: 100%;
          float: none; }
  #tovaru-open .tovaru-open-right {
    float: left;
    width: 260px;
    margin-left: -260px;
    background: #f1f1f1; }
    @media only screen and (max-width: 479px) {
      #tovaru-open .tovaru-open-right {
        float: none;
        width: 100%;
        margin-left: 0px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #tovaru-open .tovaru-open-right {
        float: none;
        width: 100%;
        margin-left: 0px; } }
    #tovaru-open .tovaru-open-right .padding {
      padding: 38px 30px 20px 30px; }
    #tovaru-open .tovaru-open-right .nalichie {
      display: block;
      text-align: center;
      font-size: 14px;
      margin-bottom: 10px;
      font-family: "SegoeUIRegular";
      font-weight: normal; }
      #tovaru-open .tovaru-open-right .nalichie.net-nalichia {
        color: #d23600; }
      #tovaru-open .tovaru-open-right .nalichie.est-nalichie {
        color: #2cb425; }
      #tovaru-open .tovaru-open-right .nalichie.pod-zakaz {
        color: #033657; }
    #tovaru-open .tovaru-open-right .old-price {
      font-family: "SegoeUIRegular";
      font-weight: normal;
      font-size: 32px;
      line-height: 130%;
      text-align: center;
      text-decoration: line-through;
      color: #333; }
    #tovaru-open .tovaru-open-right .fancy-price {
      font-family: "SegoeUIRegular";
      font-weight: normal;
      font-size: 36px;
      line-height: 130%;
      text-align: center; }
    #tovaru-open .tovaru-open-right .shs-tocart-line {
      height: 1px;
      border: none;
      border-top: 1px solid #bdbdbd;
      margin-bottom: 20px; }
    #tovaru-open .tovaru-open-right input.tovar-count {
      width: calc(100% - 32px);
      padding: 10px 15px;
      border: 1px solid #5a372b;
      background: #fff;
      -webkit-appearance: none;
      margin-bottom: 10px; }
    #tovaru-open .tovaru-open-right .shk-but {
      width: 100%;
      background-color: #f1f1f1;
      /* Green */
      border: 2px solid #2f251a;
      color: #2f251a;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      font-family: "SegoeUIBold";
      text-transform: uppercase;
      margin-bottom: 20px; }
    #tovaru-open .tovaru-open-right .parametru {
      margin-bottom: 20px; }
    #tovaru-open .tovaru-open-right .parametru pre {
      font-size: 13px;
      font-family: "SegoeUIRegular";
      font-weight: normal;
      line-height: 130%; }
    #tovaru-open .tovaru-open-right .parametru pre b {
      margin-top: 20px;
      width: 100%;
      line-height: 170%; }
    #tovaru-open .tovaru-open-right .parametru p {
      font-size: 13px;
      font-family: "SegoeUIRegular";
      font-weight: normal;
      line-height: 130%; }
    #tovaru-open .tovaru-open-right .parametru p b {
      margin-top: 20px;
      width: 100%;
      line-height: 170%; }
    #tovaru-open .tovaru-open-right .opis {
      margin-bottom: 20px;
      font-size: 12px;
      font-family: "SegoeUIRegular";
      font-weight: normal;
      line-height: 130%;
      text-align: justify; }
    #tovaru-open .tovaru-open-right .opis h3 {
      font-size: 13px;
      font-family: "SegoeUIBold";
      font-weight: normal;
      line-height: 130%;
      margin-bottom: 10px; }
    #tovaru-open .tovaru-open-right .tovaru-line-open-right h3 {
      font-size: 13px;
      font-family: "SegoeUIBold";
      font-weight: normal;
      line-height: 130%;
      margin-bottom: 10px;
      text-align: center; }
    #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart {
      width: 98%;
      margin: 0 0.5% 24px 0.5%;
      vertical-align: top;
      /*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/ }
      @media only screen and (max-width: 479px) {
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart {
          width: 100%;
          margin: 0 0 40px 0; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart {
          width: 50%; } }
      #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img {
        margin-bottom: 16px;
        display: block;
        /*скрываем jpg, показываем png*/ }
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img img {
          max-width: 100%;
          border-bottom: 2px solid #fff; }
        @media only screen and (max-width: 479px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img {
            margin-bottom: 12px; } }
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img .tovaru-cart-img-png {
          display: none; }
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img .tovaru-cart-img-jpg {
          display: block; }
        @media only screen and (min-width: 980px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img {
            background: #f0f0f0; }
            #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img:hover {
              background: #fbfbfb; }
            #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img .tovaru-cart-img-png {
              display: block; }
            #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-img .tovaru-cart-img-jpg {
              display: none; } }
      #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title {
        font-family: "SegoeUIRegular";
        font-weight: normal;
        font-size: 14px;
        color: #0f0f0f;
        line-height: 130%;
        display: block;
        padding: 10px;
        width: calc(100% - 20px);
        text-align: center; }
        @media only screen and (max-width: 479px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title {
            font-size: 14px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title {
            font-size: 14px; }
            #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .tovaru-cart-title:hover {
              background: #fbfbfb; } }
      #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-line {
        width: 100%; }
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-line:after {
          content: ".";
          display: block;
          clear: both;
          visibility: hidden;
          line-height: 0;
          height: 0; }
      #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-old {
        font-family: "SegoeUIRegular";
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        float: left;
        width: 50%;
        text-align: center;
        color: #333;
        margin-bottom: 5px;
        text-decoration: line-through; }
        @media only screen and (max-width: 479px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-old {
            font-size: 16px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-old {
            font-size: 18px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-old {
            font-size: 18px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-old {
            font-size: 18px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena-old {
            font-size: 20px; } }
      #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena {
        font-family: "SegoeUIBold";
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        float: right;
        width: 50%;
        text-align: center;
        margin-bottom: 5px; }
        @media only screen and (max-width: 479px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena {
            font-size: 16px; } }
        @media only screen and (min-width: 480px) and (max-width: 599px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena {
            font-size: 18px; } }
        @media only screen and (min-width: 600px) and (max-width: 767px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena {
            font-size: 18px; } }
        @media only screen and (min-width: 768px) and (max-width: 979px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena {
            font-size: 18px; } }
        @media only screen and (min-width: 980px) {
          #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena {
            font-size: 20px; } }
        #tovaru-open .tovaru-open-right .tovaru-line-open-right .tovaru-cart .cena.cena-centr {
          width: 100%;
          float: none; }

#portfolio .padding {
  padding-top: 20px;
  padding-bottom: 20px; }

#portfolio .center-line h1 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: normal; }
  @media only screen and (max-width: 479px) {
    #portfolio .center-line h1 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #portfolio .center-line h1 {
      font-size: 16px; } }
  #portfolio .center-line h1 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      #portfolio .center-line h1 span {
        padding: 0px;
        background: none; } }

#portfolio .portfolio-premium {
  width: 100%;
  text-align: center;
  margin-bottom: 20px; }
  #portfolio .portfolio-premium img {
    max-width: 100%; }
  #portfolio .portfolio-premium .portfolio-premium-small {
    display: none; }
  #portfolio .portfolio-premium .portfolio-premium-big {
    display: block; }
  @media only screen and (max-width: 479px) {
    #portfolio .portfolio-premium .portfolio-premium-small {
      display: block; }
    #portfolio .portfolio-premium .portfolio-premium-big {
      display: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #portfolio .portfolio-premium .portfolio-premium-small {
      display: block; }
    #portfolio .portfolio-premium .portfolio-premium-big {
      display: none; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #portfolio .portfolio-premium .portfolio-premium-small {
      display: block; }
    #portfolio .portfolio-premium .portfolio-premium-big {
      display: none; } }

#portfolio .portfolio-cart {
  padding: 0px 5px;
  margin: 3px;
  margin-bottom: 40px; }
  #portfolio .portfolio-cart img {
    max-width: 100%;
    margin-bottom: 12px; }
  #portfolio .portfolio-cart h3 {
    font-family: "SegoeUIBold";
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 12px;
    line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #portfolio .portfolio-cart h3 {
        font-size: 14px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #portfolio .portfolio-cart h3 {
        font-size: 16px; } }
  #portfolio .portfolio-cart a.portfolio-more {
    color: #000;
    text-decoration: underline; }
  #portfolio .portfolio-cart p {
    font-family: "SegoeUIRegular";
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 20px; }
  #portfolio .portfolio-cart .portfolio-owl a {
    padding: 0px 5px;
    margin: 3px; }
  #portfolio .portfolio-cart .portfolio-owl img {
    max-width: 95%; }
  #portfolio .portfolio-cart .portfolio-owl {
    display: block; }
  #portfolio .portfolio-cart .portfolio-gallery {
    display: none; }
  #portfolio .portfolio-cart .portfolio-more-open {
    display: block;
    margin-top: 10px; }
  #portfolio .portfolio-cart .portfolio-more-close {
    display: none;
    margin-top: 10px; }
  #portfolio .portfolio-cart.open .portfolio-gallery {
    display: block; }
  #portfolio .portfolio-cart.open .portfolio-owl {
    display: none !important; }
  #portfolio .portfolio-cart.open .portfolio-more-open {
    display: none; }
  #portfolio .portfolio-cart.open .portfolio-more-close {
    display: block; }
  #portfolio .portfolio-cart .portfolio-gallery a {
    display: inline-block;
    position: relative;
    width: 18.5%;
    margin: 0 0.5% 0.5% 0.5%;
    text-align: center;
    vertical-align: bottom;
    /* background:#f5f5f5; */ }
    @media only screen and (max-width: 479px) {
      #portfolio .portfolio-cart .portfolio-gallery a {
        width: 100%;
        margin: 0 0 20px 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #portfolio .portfolio-cart .portfolio-gallery a {
        width: 48%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #portfolio .portfolio-cart .portfolio-gallery a {
        width: 31.5%; } }
  #portfolio .portfolio-cart .owl-theme .owl-controls .owl-page span {
    background: #000; }
  #portfolio .portfolio-cart .portfolio-foto a span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../images/video-play-icon.png) center center no-repeat; }

#cartInner.full .shop-cart-del-big {
  text-align: left;
  font-family: "SegoeUIBold";
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 18px; }
  #cartInner.full .shop-cart-del-big a {
    border-bottom: 1px dashed #000;
    color: #000; }

#cartInner.full .shop-table-border ul li {
  display: inline-block;
  width: 20.5%;
  margin: 0 0.5% 24px 0.5%;
  vertical-align: top;
  border: 16px solid #f0f0f0;
  background: #f0f0f0;
  /*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/ }
  @media only screen and (max-width: 479px) {
    #cartInner.full .shop-table-border ul li {
      width: 94%;
      margin: 0 0 40px 0;
      border: 8px solid #f0f0f0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #cartInner.full .shop-table-border ul li {
      width: 44%;
      border: 4px solid #f0f0f0; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #cartInner.full .shop-table-border ul li {
      width: 28.5%;
      border: 8px solid #f0f0f0; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #cartInner.full .shop-table-border ul li {
      width: 29.5%;
      border: 8px solid #f0f0f0; } }
  @media only screen and (min-width: 980px) and (max-width: 1200px) {
    #cartInner.full .shop-table-border ul li {
      border: 8px solid #f0f0f0; } }
  #cartInner.full .shop-table-border ul li a {
    color: #0f0f0f; }
  #cartInner.full .shop-table-border ul li .tovar-img {
    margin-bottom: 16px;
    display: block;
    /*скрываем jpg, показываем png*/ }
    #cartInner.full .shop-table-border ul li .tovar-img img {
      max-width: 100%;
      border-bottom: 2px solid #fff; }
    @media only screen and (max-width: 479px) {
      #cartInner.full .shop-table-border ul li .tovar-img {
        margin-bottom: 12px; } }
    #cartInner.full .shop-table-border ul li .tovar-img .tovaru-cart-img-png {
      display: none; }
    #cartInner.full .shop-table-border ul li .tovar-img .tovaru-cart-img-jpg {
      display: block; }
    @media only screen and (min-width: 980px) {
      #cartInner.full .shop-table-border ul li .tovar-img {
        background: #f0f0f0; }
        #cartInner.full .shop-table-border ul li .tovar-img:hover {
          background: #fbfbfb; }
        #cartInner.full .shop-table-border ul li .tovar-img .tovaru-cart-img-png {
          display: block; }
        #cartInner.full .shop-table-border ul li .tovar-img .tovaru-cart-img-jpg {
          display: none; } }
  #cartInner.full .shop-table-border ul li .buy-block.shop-block {
    text-align: center; }
  #cartInner.full .shop-table-border ul li .addit-data {
    margin-bottom: 10px;
    font-family: "SegoeUIRegular";
    font-weight: normal;
    font-size: 14px;
    text-align: center; }
  #cartInner.full .shop-table-border ul li .shk-del {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    text-align: center; }
  #cartInner.full .shop-table-border ul li .tovar-name {
    font-family: "SegoeUIRegular";
    font-weight: normal;
    font-size: 16px;
    color: #0f0f0f;
    line-height: 130%;
    display: block;
    padding: 10px;
    width: calc(100% - 20px);
    text-align: center; }
    @media only screen and (max-width: 479px) {
      #cartInner.full .shop-table-border ul li .tovar-name {
        font-size: 14px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #cartInner.full .shop-table-border ul li .tovar-name {
        font-size: 14px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #cartInner.full .shop-table-border ul li .tovar-name {
        font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #cartInner.full .shop-table-border ul li .tovar-name {
        font-size: 14px; } }
    @media only screen and (min-width: 980px) {
      #cartInner.full .shop-table-border ul li .tovar-name {
        font-size: 14px; }
        #cartInner.full .shop-table-border ul li .tovar-name:hover {
          background: #fbfbfb; } }
    #cartInner.full .shop-table-border ul li .tovar-name .net-nalichia {
      color: #d23600; }
    #cartInner.full .shop-table-border ul li .tovar-name .est-nalichie {
      color: #2cb425; }
    #cartInner.full .shop-table-border ul li .tovar-name .pod-zakaz {
      color: #033657; }
  #cartInner.full .shop-table-border ul li .cena-line {
    width: 100%; }
    #cartInner.full .shop-table-border ul li .cena-line:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  #cartInner.full .shop-table-border ul li .cena-old {
    font-family: "SegoeUIRegular";
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;
    float: left;
    width: 50%;
    text-align: center;
    color: #333;
    margin-bottom: 5px;
    text-decoration: line-through; }
    @media only screen and (max-width: 479px) {
      #cartInner.full .shop-table-border ul li .cena-old {
        font-size: 16px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #cartInner.full .shop-table-border ul li .cena-old {
        font-size: 18px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #cartInner.full .shop-table-border ul li .cena-old {
        font-size: 18px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #cartInner.full .shop-table-border ul li .cena-old {
        font-size: 18px; } }
    @media only screen and (min-width: 980px) {
      #cartInner.full .shop-table-border ul li .cena-old {
        font-size: 20px; } }
  #cartInner.full .shop-table-border ul li .cena {
    font-family: "SegoeUIBold";
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;
    float: right;
    width: 50%;
    text-align: center;
    margin-bottom: 5px; }
    @media only screen and (max-width: 479px) {
      #cartInner.full .shop-table-border ul li .cena {
        font-size: 16px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #cartInner.full .shop-table-border ul li .cena {
        font-size: 18px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #cartInner.full .shop-table-border ul li .cena {
        font-size: 18px; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #cartInner.full .shop-table-border ul li .cena {
        font-size: 18px; } }
    @media only screen and (min-width: 980px) {
      #cartInner.full .shop-table-border ul li .cena {
        font-size: 20px; } }
    #cartInner.full .shop-table-border ul li .cena.cena-centr {
      width: 100%;
      float: none; }

.shop-cart-summ-big {
  font-family: "SegoeUIBold";
  font-weight: normal;
  font-size: 22px;
  line-height: 130%; }
  .shop-cart-summ-big > b {
    color: #59aa49; }
  @media only screen and (max-width: 479px) {
    .shop-cart-summ-big {
      font-size: 16px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .shop-cart-summ-big {
      font-size: 18px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .shop-cart-summ-big {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .shop-cart-summ-big {
      font-size: 18px; } }
  @media only screen and (min-width: 980px) {
    .shop-cart-summ-big {
      font-size: 20px; } }

@media only screen and (max-width: 479px) {
  .form-style tr {
    display: block;
    margin-bottom: 5px; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .form-style tr {
    display: block;
    margin-bottom: 5px; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .form-style tr {
    display: block;
    margin-bottom: 5px; } }

.form-style tr td {
  font-family: "SegoeUIRegular";
  font-weight: normal;
  font-size: 16px;
  color: #0f0f0f;
  line-height: 130%;
  padding: 0px 5px 5px 5px; }
  @media only screen and (max-width: 479px) {
    .form-style tr td {
      display: inline-block;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .form-style tr td {
      display: inline-block;
      width: 100%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .form-style tr td {
      display: inline-block;
      width: 100%; } }

.reviews {
  padding: 0px 10px;
  width: calc(100% - 20px);
  /*указываем общую ширину блока с колонками*/
  text-align: justify;
  /*выравнивание текста внутри колонок*/
  column-count: 2;
  /*количество колонок, на которое хотим разбить текст*/
  -moz-column-count: 2;
  /*для мозилы*/
  -webkit-column-count: 2;
  /*для webkit браузеров*/
  column-gap: 20px;
  /*отступ между колонками*/
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  /* column-rule: 1px solid #000; если требуется по дизайну, разделяем колонки линией
-moz-column-rule: 1px solid #000;
-webkit-column-rule: 1px solid #000;*/ }
  @media only screen and (max-width: 479px) {
    .reviews {
      column-count: 1;
      margin: 0 0 40px 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .reviews {
      column-count: 1; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .reviews {
      column-count: 1; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .reviews {
      column-count: 2; } }

.jot-comment {
  /* display:inline-block; width:47.5%; margin:0 0.5% 18px 0.5%; 
	 vertical-align:top;
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:96%; }
				@include respond-to(small-tablets) { width:96%;} */ }
  .jot-comment .jot-avatar {
    display: none; }
  .jot-comment .jot-name {
    font-family: "SegoeUIBold";
    font-size: 18px;
    font-weight: normal; }
  .jot-comment .jot-message {
    font-family: "SegoeUIRegular";
    font-size: 14px;
    font-weight: normal; }

.jot-comment-entry {
  background: #fafafa;
  border: none; }

#footer {
  background: #534a3f;
  color: #fff;
  font-family: "SegoeUIRegular"; }
  #footer a {
    color: #fff; }
  #footer .footer-padding-top {
    padding: 32px 0px 0px 0px; }
    #footer .footer-padding-top:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    @media only screen and (max-width: 479px) {
      #footer .footer-padding-top {
        padding: 32px 10px 0px 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .footer-padding-top {
        padding: 32px 10px 0px 10px; } }
    #footer .footer-padding-top .footer-block {
      width: 22%;
      display: inline-block;
      padding: 0px 1%;
      vertical-align: top; }
      @media only screen and (max-width: 479px) {
        #footer .footer-padding-top .footer-block {
          float: none;
          width: 100%;
          margin-bottom: 32px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #footer .footer-padding-top .footer-block {
          float: none;
          width: 100%;
          margin-bottom: 32px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #footer .footer-padding-top .footer-block {
          width: 43%;
          padding: 0px 3%;
          margin-bottom: 32px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #footer .footer-padding-top .footer-block {
          width: 43%;
          padding: 0px 3%;
          margin-bottom: 32px; } }
      #footer .footer-padding-top .footer-block h2 {
        margin-bottom: 18px;
        font-family: "SegoeUISemiBold";
        font-size: 18px;
        font-weight: normal;
        text-transform: uppercase; }
      #footer .footer-padding-top .footer-block ul li {
        margin-bottom: 10px;
        font-size: 14px; }
      #footer .footer-padding-top .footer-block p {
        font-size: 14px;
        line-height: 130%; }
    #footer .footer-padding-top .footer-kotaktu .footer-kontaktu-line {
      margin-bottom: 18px; }
      #footer .footer-padding-top .footer-kotaktu .footer-kontaktu-line:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0; }
      #footer .footer-padding-top .footer-kotaktu .footer-kontaktu-line .footer-kontaktu-icon {
        float: left;
        width: 24px; }
  #footer .footer-social {
    text-align: center;
    margin-bottom: 18px; }
  #footer .footer-bottom {
    background: #2f251a;
    text-align: center;
    font-size: 14px;
    line-height: 40px; }

.left {
  float: left; }

.right {
  float: right; }

.mp-o {
  margin: 2px 0;
  padding: 2px 0; }

.center {
  text-align: center; }

.relative {
  position: relative; }

.block {
  width: 100%;
  box-sizing: content-box; }
  .block .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      .block .pod-block {
        max-width: 1200px; } }

.left-content {
  width: 29%;
  float: left; }
  .left-content .padding {
    padding: 2% 1% 0% 3%; }
  @media only screen and (max-width: 479px) {
    .left-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .left-content {
      float: none;
      width: 100%; } }

.right-content {
  width: 69%;
  float: right; }
  .right-content .padding {
    padding: 2% 0% 0% 1%; }
  @media only screen and (max-width: 479px) {
    .right-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content {
      float: none;
      width: 100%; } }
  .right-content p {
    line-height: 130%; }

span.skype_c2c_container img.skype_c2c_logo_img {
  display: none !important; }

.center-line-global h2 {
  text-align: center;
  font-family: "SegoeUIBold";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  background: url(../img/line-dotted.png) center left repeat-x;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: normal; }
  @media only screen and (max-width: 479px) {
    .center-line-global h2 {
      font-size: 14px;
      background: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .center-line-global h2 {
      font-size: 16px; } }
  .center-line-global h2 a {
    color: #000; }
  .center-line-global h2 span {
    background: #fff;
    padding: 10px 20px 10px 20px; }
    @media only screen and (max-width: 479px) {
      .center-line-global h2 span {
        padding: 0px;
        background: none; } }

.text-line-global {
  margin: 5px 10px 20px 10px; }
  .text-line-global p {
    font-family: "SegoeUIRegular";
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 10px; }

.menu-link-portfolio {
  color: #eed8a3 !important; }
  .menu-link-portfolio:hover {
    color: #d23600 !important; }

.search {
  position: absolute;
  top: 76px;
  right: 10px;
  background: #fff;
  z-index: 101;
  padding: 5px 10px;
  box-shadow: 0px 0px 5px 2px #7B7878; }

#search {
  width: 175px;
  font-size: 14px;
  border: 1px solid gainsboro;
  box-sizing: border-box;
  padding: 3px; }

#search:hover {
  border: 1px solid #707070; }

#search:focus {
  border: 1px solid #707070; }

#search-result {
  box-sizing: border-box;
  width: 375px; }
  @media only screen and (max-width: 479px) {
    #search-result {
      position: absolute;
      top: 213px;
      right: 0px;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #search-result {
      position: absolute;
      top: 213px;
      right: 0px;
      width: 100%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #search-result {
      position: absolute;
      top: 213px;
      right: 0px;
      width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #search-result {
      position: absolute;
      top: 40px;
      right: 0px; } }
  @media only screen and (min-width: 980px) {
    #search-result {
      position: absolute;
      top: 40px;
      right: 0px; } }
  @media only screen and (min-width: 1200px) {
    #search-result {
      position: absolute;
      top: 40px;
      right: 0px; } }

.search-inner {
  background-color: #fff;
  z-index: 107;
  position: relative;
  transition: all 0.2s linear;
  border-top: none;
  -webkit-box-shadow: 3px 5px 8px rgba(50, 50, 50, 0.6);
  -moz-box-shadow: 3px 5px 8px rgba(50, 50, 50, 0.6);
  box-shadow: 3px 5px 8px rgba(50, 50, 50, 0.6); }

.search-inner a {
  display: block;
  padding: 7px 10px;
  text-decoration: none;
  transition: all 0.2s linear;
  font-size: 14px;
  color: #000;
  line-height: 130%; }

.search-inner a:hover {
  background-color: #eee; }

.search-inner a:after {
  content: "\2192";
  float: right; }

.sticky {
  position: fixed;
  z-index: 101; }

.stop {
  position: relative;
  z-index: 101; }

.fancy-param {
  margin-bottom: 10px;
  margin-top: -10px; }

/* helper box */
#stuffHelper {
  position: absolute;
  z-index: 10000;
  width: 210px;
  padding: 10px 5px;
  background-color: #fff;
  border: 1px solid #888;
  display: none;
  font-family: "SegoeUIRegular";
  font-size: 14px;
  font-weight: normal; }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  body.korzina-fixed #stuffHelper {
    position: fixed; } }

@media only screen and (min-width: 980px) {
  body.korzina-fixed #stuffHelper {
    position: fixed; } }

body.korzina #stuffHelper, body.korzina-fixed-stop #stuffHelper {
  position: absolute; }

#stuffHelperName {
  display: block;
  margin-bottom: 10px;
  font-family: "SegoeUIRegular";
  font-size: 16px;
  font-weight: normal; }

#stuffHelper div {
  /*padding:5px 0; */
  text-align: center; }

/* progressbar */
#shkLoading {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 100;
  background: url(../img/ajax-loader.gif) center center no-repeat #fff;
  border: 1px solid #888;
  display: none; }

/*parametru*/
.radio {
  display: inline-block;
  padding-right: 20px;
  font-family: "SegoeUIRegular";
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  cursor: pointer; }

.radio:hover .inner {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: .5; }

.radio input {
  width: 1px;
  height: 1px;
  opacity: 0; }

.radio input:checked + .outer .inner {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.radio input:checked + .outer {
  border: 2px solid #514A46; }

.radio input:focus + .outer .inner {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background-color: #514A46; }

.radio .outer {
  width: 14px;
  height: 14px;
  display: block;
  float: left;
  margin: 3px;
  border: 2px solid #8E7A6C;
  border-radius: 50%;
  background-color: #fff; }

.radio .inner {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 10px;
  height: 10px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  display: block;
  margin: 2px;
  border-radius: 50%;
  background-color: #514A46;
  opacity: 0; }

/*shopkeeper*/
.shs-tocart form label,
.shs-count label {
  display: inline-block;
  position: relative; }

.shs-tocart form label [name*='count'],
.shs-count label [name*='count'] {
  display: inline-block;
  padding: 0.2em 0.3em;
  width: 100%; }

.shs-tocart form label .field-arr,
.shs-count label .field-arr {
  display: block;
  height: 10px;
  margin: 1px;
  vertical-align: middle;
  width: 17px; }

#stuffCount {
  margin-bottom: 10px; }

.field-arr {
  position: absolute;
  right: -10px; }
  .field-arr.up {
    top: 0px; }
  .field-arr.down {
    bottom: 0px; }

#confirmButton {
  margin-left: 5%;
  float: left;
  font-size: 16px;
  padding: 5px 40px;
  text-align: center; }

#cancelButton {
  margin-right: 5%;
  float: right;
  font-size: 14px;
  padding: 5px;
  text-align: center; }

.shs-tocart form label .field-arr.up,
.shs-count label .field-arr.up {
  background: url("../assets/snippets/shopkeeper/style/default/img/arr_up.gif") no-repeat center center; }

.shs-tocart form label .field-arr.down,
.shs-count label .field-arr.down {
  background: url("../assets/snippets/shopkeeper/style/default/img/arr_down.gif") no-repeat center center; }

/* скрываем кнопку ответить и линк на отзыв */
.jot-perma, .jot-reply {
  display: none; }
