
.reviews {

padding:0px 10px;
	
	width:calc(100% - 20px); /*указываем общую ширину блока с колонками*/
text-align:justify; /*выравнивание текста внутри колонок*/
column-count: 2; /*количество колонок, на которое хотим разбить текст*/
-moz-column-count: 2; /*для мозилы*/
-webkit-column-count: 2; /*для webkit браузеров*/
column-gap: 20px; /*отступ между колонками*/
-moz-column-gap: 20px;
-webkit-column-gap: 20px;
/* column-rule: 1px solid #000; если требуется по дизайну, разделяем колонки линией
-moz-column-rule: 1px solid #000;
-webkit-column-rule: 1px solid #000;*/

				@include respond-to(handhelds) { column-count: 1; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { column-count: 1; }
				@include respond-to(small-tablets) { column-count: 1;}
				@include respond-to(tablets) { column-count: 2;}

}



.jot-comment {


/* display:inline-block; width:47.5%; margin:0 0.5% 18px 0.5%; 
	 vertical-align:top;
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:96%; }
				@include respond-to(small-tablets) { width:96%;} */




.jot-avatar {display: none;}
.jot-name {font-family: "SegoeUIBold";  font-size:18px; font-weight:normal;}
.jot-message {font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal;}


}


.jot-comment-entry {background: #fafafa; border: none;}