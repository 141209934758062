
@import "reset";
@import "font";



$calibri : Arial;
/* $calibri : 'Calibri'; */
/* $gothic : 'franklin-gothic-medium'; */
$gothic : Arial;

body{font-family: SegoeUILight, sans-serif;}

// Создаем mixin
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: 479px) { @content; }
  }
  @else if $media == wide-handhelds {
    @media only screen and (min-width: 480px) and (max-width: 599px) { @content; }
  }
  @else if $media == small-tablets {
    @media only screen and (min-width: 600px) and (max-width: 767px) { @content; }
  }  
  @else if $media == tablets {
    @media only screen and (min-width: 768px) and (max-width: 979px) { @content; }
  }
  @else if $media == display {
    @media only screen and (min-width: 980px) { @content; }
  }
  @else if $media == display-big {
    @media only screen and (min-width: 1200px) { @content; }
  }
}

@mixin clearfix { &:after {  content: ".";  display: block;  clear: both;  visibility: hidden;  line-height: 0;  height: 0;} }
// @include clearfix;

.clear {@include clearfix;}

@mixin pod-block-100 {
		margin: 0 auto; position: relative; box-sizing: content-box;
		
			// Для телефонов
			@include respond-to(handhelds) { width: 100%;  }
			// Для телефонов с широким экраном
			@include respond-to(wide-handhelds) { width: 100%;  }
			// Для маленьких планшетов
			@include respond-to(small-tablets) { width: 100%;  }
			// Для планшетов
			@include respond-to(tablets) { width: 100%;  }
			// Для экранов
			@include respond-to(display) {max-width:1200px;  }

}

// @include pod-block-100;


@mixin pod-block-adaptive {



 		min-height: 50px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 50px;
		
	
}
// @include pod-block-adaptive;

@mixin relative-null { position:relative; top:auto; left:auto;  bottom:auto; right:auto; }
// @include relative-null;

@mixin relative-null-margin-bottom { position:relative; top:auto; left:auto;  bottom:auto; right:auto; margin-bottom: 12px; }
// @include relative-null-margin-bottom;

// миксин для стилизации начала списков
@mixin counter($var){
	counter-reset: $var;
	> li, > dt{
		&:before{
			content: counter($var);
			content-increment: $var;
		}
	}
}
// @include counter;



//миксин для кнопок
@mixin btn{
 	float: right; margin-right: 15px; background: url(../img/euro_button.png); background-size:cover;color: #fff; border: none; border-radius: 6px; 			    	   
		&:hover{ background: #FC0808 ; background-size:cover;}				    	
}

// @include btn;


//миксин для плавной анимации

@mixin hover-anim {
&:hover{
	transition: all 0.6s;
	-moz-transition: all 0.6s;
	-webkit-transition: all 0.6s;
	-o-transition: all 0.6s;
}			

}


// @include hover-anim;



@import "header";
@import "navigation";

@import "main-page";
@import "cart";
@import "cart-open";

@import "portfolio";

@import "korzina";
@import "otzuvu";

@import "footer";




.left{ float: left; }
.right{ float: right;}
.mp-o{ margin: 2px 0; padding: 2px 0;}
.center{ text-align: center;}
.relative {position:relative;}

.block {
	width: 100%; box-sizing: content-box; 
	.pod-block { 

		 margin: 0 auto; position: relative; box-sizing: content-box;
		// Для телефонов
		@include respond-to(handhelds) { width: 100%; };
		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) { width: 100%; };
		// Для планшетов
		@include respond-to(small-tablets) { width: 100%; };
		@include respond-to(tablets) { width: 100%; };
		// Для экранов
		@include respond-to(display) { max-width: 1200px; };
		
	}
}

.left-content {width: 29%; float: left;
			.padding { padding: 2% 1% 0% 3%; }

// Для телефонов до 480
	@include respond-to(handhelds) { float: none; width: 100%; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { float: none; width: 100%; };
	// Для планшетов до 979
	@include respond-to(tablets) {  };
	// Для экранов от 980
	@include respond-to(display) {  };
		}


.right-content {width: 69%; float: right;
.padding { padding: 2% 0% 0% 1%;}
			// Для телефонов до 480
			@include respond-to(handhelds) { float: none; width: 100%; };
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { float: none; width: 100%; };


p {line-height: 130%;}

}


span.skype_c2c_container img.skype_c2c_logo_img {display: none!important;}



.center-line-global { 
		h2 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; font-weight:normal;
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
			a {color:#000;}
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
} //center-line-global

.text-line-global { 
margin: 5px 10px 20px 10px; 

p { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px;  line-height:130%; color:#000000; margin-bottom: 10px;}
	

}



.menu-link-portfolio {color: #eed8a3!important;  
			&:hover {color:#d23600!important;}
				
}


.search { position: absolute; top: 76px; right: 10px; background: #fff; z-index: 101; padding: 5px 10px; box-shadow: 0px 0px 5px 2px #7B7878; }
#search {
		width: 175px;
		font-size: 14px;
		border: 1px solid gainsboro; 
		box-sizing: border-box;
		padding: 3px;
	}
	#search:hover {	border: 1px solid #707070;}
	#search:focus {	border: 1px solid #707070;}
	#search-result {box-sizing: border-box;	width: 375px; 
			@include respond-to(handhelds) { position: absolute; top:213px; right:0px; width: 100%;}
			@include respond-to(wide-handhelds) { position: absolute; top:213px; right:0px; width: 100%; }
					@include respond-to(small-tablets) { position: absolute; top:213px; right:0px; width: 100%; };
			@include respond-to(tablets) {position: absolute; top:40px; right:0px;}
			@include respond-to(display) {position: absolute; top:40px; right:0px; }
				// 1200 < 
				@include respond-to(display-big) { position: absolute; top:40px; right:0px; }


	}

	.search-inner { 
		background-color: #fff;
		z-index: 107; 
		position: relative; 
		transition: all 0.2s linear;  
	    border-top: none; 
		
		-webkit-box-shadow: 3px 5px 8px rgba(50, 50, 50, 0.6);
-moz-box-shadow:    3px 5px 8px rgba(50, 50, 50, 0.6);
box-shadow:         3px 5px 8px rgba(50, 50, 50, 0.6);
		
	}


	.search-inner a { 
		display: block; 
		padding: 7px 10px; 
		text-decoration: none; 
		transition: all 0.2s linear; 
		font-size: 14px;
		color:#000;
		line-height: 130%;
	}
	.search-inner a:hover { 
		background-color: #eee; 
	}
	.search-inner a:after { 
		content: "\2192"; 
		float: right; 
	}


.sticky {
  position: fixed;
  z-index: 101;
}
.stop {
  position: relative;
  z-index: 101;
}


.fancy-param {margin-bottom: 10px; margin-top: -10px;}

/* helper box */
#stuffHelper {position: absolute; z-index:10000; width:210px; padding:10px 5px; background-color:#fff; border:1px solid #888; display:none; font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal; }

body.korzina-fixed {

			#stuffHelper {
			 	@include respond-to(tablets) {position: fixed; }
				@include respond-to(display) {position: fixed;  } 
							}
					}

body.korzina #stuffHelper, body.korzina-fixed-stop #stuffHelper{position: absolute;}

#stuffHelperName {display: block; margin-bottom: 10px; font-family: "SegoeUIRegular";  font-size:16px; font-weight:normal; }
#stuffHelper div {/*padding:5px 0; */text-align: center;}

/* progressbar */
#shkLoading {width:30px; height:30px; position: absolute; z-index:100; background:url(../img/ajax-loader.gif) center center no-repeat #fff; border:1px solid #888; display: none;}



/*parametru*/
.radio {
  display: inline-block;
  padding-right: 20px;
font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal; 
  line-height: 24px;
  cursor: pointer;
}

.radio:hover .inner {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: .5;
}

.radio input {
  width: 1px;
  height: 1px;
  opacity: 0;
}



.radio input:checked + .outer .inner {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.radio input:checked + .outer { border: 2px solid #514A46; }

.radio input:focus + .outer .inner {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background-color: #514A46;
}

.radio .outer {
  width: 14px;
  height: 14px;
  display: block;
  float: left;
  margin: 3px;
  border: 2px solid #8E7A6C;
  border-radius: 50%;
  background-color: #fff;
}

.radio .inner {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 10px;
  height: 10px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  display: block;
  margin: 2px;
  border-radius: 50%;
  background-color: #514A46;
  opacity: 0;
}



/*shopkeeper*/
.shs-tocart form label,
.shs-count label {
 display: inline-block;
 position: relative;
}
.shs-tocart form label [name*='count'],
.shs-count label [name*='count'] {
  display: inline-block;
  padding: 0.2em 0.3em;
  width: 100%;
}
.shs-tocart form label .field-arr,
.shs-count label .field-arr {
  display: block;
  height: 10px;
  margin: 1px;
  vertical-align: middle;
  width: 17px;
}


#stuffCount {margin-bottom: 10px;}

.field-arr {position: absolute; right:-10px;
			&.up {top:0px;}
			&.down {bottom: 0px;}

}

#confirmButton {margin-left:5%; float: left; font-size: 16px;   padding: 5px 40px; text-align: center;}
#cancelButton {margin-right: 5%; float: right;  font-size: 14px;  padding: 5px; text-align: center;}


.shs-tocart form label .field-arr.up,
.shs-count label .field-arr.up {
 background: url("../assets/snippets/shopkeeper/style/default/img/arr_up.gif") no-repeat center center;
}
.shs-tocart form label .field-arr.down,
.shs-count label .field-arr.down {
 background: url("../assets/snippets/shopkeeper/style/default/img/arr_down.gif") no-repeat center center;
}



/* скрываем кнопку ответить и линк на отзыв */

.jot-perma, .jot-reply {display: none;}