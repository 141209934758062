#header{


.pod-block{
	@include pod-block-100;

	background: #ffffff;
	
.padding {height: 185px;
	@include respond-to(handhelds) { height: auto; padding:10px 10px 0px 10px; @include clearfix; }
	@include respond-to(wide-handhelds) { height: auto; padding:10px 10px 0px 10px;  @include clearfix; }
				}
				
.block-right-adaptive	{text-align:right;}			
				
	.header-menu { position:absolute; top:13px; left:10px;
		@include respond-to(handhelds) { @include relative-null-margin-bottom; margin-bottom:20px;}
		@include respond-to(wide-handhelds) { @include relative-null-margin-bottom; }
	
	ul li {display:inline-block; margin-right:20px; line-height:130%;
	@include respond-to(handhelds) { margin-right:10px; }
	
			a {font-family: "SegoeUIRegular"; font-size: 14px; color:#262626; text-decoration:underline;
				&:hover {text-decoration:none;}
			}
	}
	
	}//.header-menu
	

	.header-logo{ position: absolute; bottom: 18px; left: 10px; width: 500px; @include clearfix;
		@include respond-to(handhelds) { @include relative-null-margin-bottom; width: auto;  }
		@include respond-to(wide-handhelds) {@include relative-null-margin-bottom; width: auto; }
		
		
		a {font-family: "SegoeUILight"; color:#262626;}
		
		.logo-img {width:84px; height:75px; margin-right:20px; float:left; 
			@include respond-to(handhelds) { width:22.5%; margin-right:3.5%; }
			@include respond-to(wide-handhelds) { width:80px; height:70px; }
			@include respond-to(small-tablets) { width:80px; height:70px; }
	
			img { max-width: 100%; max-height: 100%; }
         }		 
		 
		 .logo-text {float:left; width:350px; 
		 		@include respond-to(handhelds) { width: 74%;  }
				@include respond-to(wide-handhelds) {width: 340px;  }
					}
		 .logo-big_text {font-size:36px; text-transform:uppercase; margin:-6px 0px 12px 0px; 
		 	@include respond-to(handhelds) { font-size:24px; }
		 	@include respond-to(small-tablets) { font-size:28px; }
		 }
         .logo-small_text { font-size:14px; text-transform:uppercase; line-height:130%; 
		 	@include respond-to(handhelds) { font-size:12px; }
			@include respond-to(small-tablets) { font-size:12px; }
		 }
	
	}//.header-logo
	
		
	
	.header-phone { position:absolute; top:13px; right:10px;
			@include respond-to(handhelds) { @include relative-null-margin-bottom; }
			@include respond-to(wide-handhelds) { @include relative-null-margin-bottom;  }
		
					p {font-family: "SegoeUILight"; font-size: 24px; text-transform:uppercase; color:#262626; line-height:130%;
					a {color:#262626;}
					@include respond-to(handhelds) { font-size:20px; }
					@include respond-to(wide-handhelds) { font-size:20px;  }
					@include respond-to(small-tablets) { font-size:20px; }
					}
	
		}//.header-phone
		
		
		.header-adress { position:absolute; top:85px; right:10px; @include clearfix; 
				@include respond-to(handhelds) { @include relative-null-margin-bottom;  }
				@include respond-to(wide-handhelds) { @include relative-null-margin-bottom;  }

						.header-adress_icon {width: 23px; height:31px; float:left; margin-right:20px;
												img { max-width: 100%; max-height: 100%; }
											}
						.header-adress_text {float:left; }
		
		
		
		} //.header-adress

		.info {position:absolute; bottom:18px; right:10px; @include clearfix; 
		.info_block {padding:12px 16px; background:#f6f5f3; font-family: "SegoeUILight"; font-size: 12px; text-transform:uppercase; color:#e90046; 

			a {color:#e90046;}

		@include respond-to(handhelds) { float:left;  }
		@include respond-to(wide-handhelds) { float:left;  }
		@include respond-to(small-tablets) { text-transform:none; }
			}
			@include respond-to(handhelds) { text-transform:none; @include relative-null-margin-bottom;  }
			@include respond-to(wide-handhelds) { text-transform:none; @include relative-null-margin-bottom;  }

		}



} // .pod-block

} // #header



