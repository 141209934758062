#footer{ background: #534a3f; color:#fff; font-family: "SegoeUIRegular";
	a {color:#fff;}
	
	.footer-padding-top{ padding: 32px 0px 0px 0px; @include clearfix;
			@include respond-to(handhelds) {  padding: 32px 10px 0px 10px; }
			@include respond-to(wide-handhelds) { padding: 32px 10px 0px 10px; }


	.footer-block {width:22%; display:inline-block; padding:0px 1%; vertical-align:top;
		@include respond-to(handhelds) {  float:none; width:100%; margin-bottom:32px; }
@include respond-to(wide-handhelds) { float:none; width:100%; margin-bottom:32px;}
@include respond-to(small-tablets) { width:43%; padding:0px 3%; margin-bottom:32px;}
@include respond-to(tablets) { width:43%; padding:0px 3%; margin-bottom:32px;}
	
	
					h2 {margin-bottom:18px; font-family: "SegoeUISemiBold";  
					font-size:18px; font-weight:normal; text-transform:uppercase;}
					ul li {margin-bottom:10px; font-size:14px;}
					p {font-size:14px; line-height:130%;}
	}
	
	.footer-kotaktu {
		.footer-kontaktu-line	{	@include clearfix; margin-bottom:18px;
			.footer-kontaktu-icon {float:left; width:24px;}
					
	
	
		}
	}
	
	
		

	}//.padding-top
	
.footer-social {text-align:center; margin-bottom:18px;}	

.footer-bottom {background:#2f251a; text-align:center; font-size:14px; line-height:40px;
}	
	
}//#footer