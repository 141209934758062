

#cartInner.full { 

.shop-cart-del-big {text-align: left; font-family: "SegoeUIBold";  font-size:22px; font-weight:normal; margin-bottom:18px; 

	a {border-bottom: 1px dashed #000; color:#000;}

	}

.shop-table-border ul li {display:inline-block; width:20.5%; margin:0 0.5% 24px 0.5%; 
		vertical-align:top; border: 16px solid #f0f0f0; background:#f0f0f0;

					/*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/
		
				@include respond-to(handhelds) { width:94%; margin:0 0 40px 0; border: 8px solid #f0f0f0;}
				@include respond-to(wide-handhelds) { width:44%; border: 4px solid #f0f0f0; }
				@include respond-to(small-tablets) { width:28.5%; border: 8px solid #f0f0f0;}
				@include respond-to(tablets) { width:29.5%; border: 8px solid #f0f0f0;}
				@media only screen and (min-width: 980px) and (max-width: 1200px) { border: 8px solid #f0f0f0; }
		

			a {color:#0f0f0f;}

			.tovar-img {   margin-bottom:16px; display: block;  
					img {max-width:100%; border-bottom:2px solid #fff;}
				@include respond-to(handhelds) { margin-bottom:12px; }

						.tovaru-cart-img-png {display: none;}
						.tovaru-cart-img-jpg {display: block;}

					/*скрываем jpg, показываем png*/
					@include respond-to(display) { 

						background:#f0f0f0; 
						&:hover {background:#fbfbfb; } 

						.tovaru-cart-img-png {display: block;}
						.tovaru-cart-img-jpg {display: none;}
					}


						
			}

			.buy-block.shop-block {text-align: center;}

			.addit-data {margin-bottom:10px; font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px; text-align:center;}

			.shk-del {display:block; margin-bottom:10px; width:100%; text-align: center;}
				
			.tovar-name { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 16px; color:#0f0f0f; line-height:130%; display:block; padding: 10px; 
				width: calc(100% - 20px); text-align: center; 
				@include respond-to(handhelds) { font-size: 14px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 14px; &:hover {background:#fbfbfb;} }	
			
			.net-nalichia {color:#d23600;}
			.est-nalichie {color:#2cb425;}
			.pod-zakaz {color:#033657;}
						
			}

			.cena-line {width:100%; @include clearfix;}

			.cena-old { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 24px; line-height:130%; float:left; width:50%; text-align:center; color:#333; 
			margin-bottom:5px; text-decoration: line-through;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }			
			}


			.cena { font-family: "SegoeUIBold"; font-weight:normal; font-size: 24px; line-height:130%; float:right; width:50%; text-align:center;
			margin-bottom:5px;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }		

			&.cena-centr {width:100%; float:none;}


			}
			
			
		} // .tovaru-cart
} // #shopCart 

.shop-cart-summ-big {font-family: "SegoeUIBold"; font-weight:normal; font-size: 22px; line-height:130%;

     &>b{color: #59aa49;}

				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }
 }

.form-style {

tr { 

				@include respond-to(handhelds) { display:block; margin-bottom: 5px;}
				@include respond-to(wide-handhelds) {display:block; margin-bottom: 5px; }
				@include respond-to(small-tablets) {display:block; margin-bottom: 5px; }

}

tr td { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 16px; color:#0f0f0f;
		 line-height:130%; padding:0px 5px 5px 5px;

			@include respond-to(handhelds) { display: inline-block; width: 100%;}
				@include respond-to(wide-handhelds) { display: inline-block; width: 100%; }
				@include respond-to(small-tablets) {display: inline-block; width: 100%; }

}


}