#main-baner {  font-family: "SegoeUILight"; text-transform:uppercase;

    min-height: 500px;
    background: transparent;
	
	@include respond-to(handhelds) { min-height: 500px;  }
@include respond-to(wide-handhelds) { min-height: 400px;  }
@include respond-to(small-tablets) { min-height: 400px;  }
@include respond-to(tablets) { min-height: 400px;  }
	
	
			@include respond-to(handhelds) { min-height: 500px; }
			@include respond-to(tablets) {  min-height: 400px; }

	.padding {padding-top:60px;}
	
	
	.main-baner-header {text-align:center; color:#fff; margin-bottom:60px;
	
    text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

	
		h2 { font-size: 48px; margin-bottom:20px; }
		h3 { font-size: 24px; margin-bottom:20px;}
		h4 { font-size: 30px; margin-bottom:20px;}
		
		@include respond-to(handhelds) { 

		h2 { font-size: 36px; margin-bottom:20px; }
		h3 { font-size: 18px; margin-bottom:20px;}
		h4 { font-size: 24px; margin-bottom:20px;}

		}
	
	} //main-baner-header
	
	
	.main-baner-line {text-align:center; 
		.main-baner-button {display:inline-block; margin:0px 10px 20px 10px; padding:10px 30px; background: rgba( 71, 52, 33, 0.6); border:1px solid #fff; color:#fff;
		font-family: "SegoeUIRegular";  font-size:20px; 
		
		@include respond-to(handhelds) { font-size:18px;} 
		
			&:hover {background: rgba( 71, 52, 33, 1);}
		}
		
	} //main-baner-line
} //#main-baner


#popular {

	.padding {padding-top:20px; padding-bottom:20px;}
	
	.center-line { 
		h2 {text-align:center; font-family: "SegoeUIBold"; font-weight:normal;  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; 
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
		
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
	} //center-line
	
	
	.pop-cart-line { @include clearfix;
		
		.pop-cart {display:inline-block; width:18.5%; margin:0 0.5% 30px 0.5%; text-align:center; 
		vertical-align:top; 
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:48%; }
				@include respond-to(small-tablets) { width:31.5%;}
		
			img {max-width:100%; margin-bottom:16px;
				@include respond-to(handhelds) { margin-bottom:12px; }
				}
			h2 { font-family: "SegoeUIBold"; font-weight:normal; font-size: 18px; text-transform:uppercase; line-height:130%;
			margin-bottom:12px;
				@include respond-to(handhelds) { font-size: 16px; margin-bottom:10px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 16px; }
			
			
			}
			p { font-family: "SegoeUIRegular"; font-size: 14px; line-height:130%; margin-bottom:16px;
				@include respond-to(handhelds) { font-size: 14px; }
				@include respond-to(wide-handhelds) { font-size: 12px; }
				@include respond-to(small-tablets) { font-size: 12px; }
				@include respond-to(tablets) { font-size: 12px; }
				@include respond-to(display) { font-size: 13px; }
				}
			
			.pop-cart-btn { 
				a { font-family: "SegoeUILight"; font-size: 14px; text-transform:uppercase; padding:5px 10px;  color:#fff; white-space:nowrap;
				 background:#2f251a;  box-shadow: 5px 5px 0px -2px rgba(0,0,0,1); 
					@include respond-to(handhelds) { font-size: 14px; }
					@include respond-to(wide-handhelds) { font-size: 12px; }
					@include respond-to(small-tablets) {font-size: 12px; }
					@include respond-to(tablets) { font-size: 12px; }
					@include respond-to(display) { font-size: 13px; }				
				
				
				&:hover {box-shadow: none;}
				}
			}
			
		} //.pop-cart
	
	}//.pop-cart-line 

} //#popular


#category-tab {

.padding {padding-top:20px; padding-bottom:20px;}


.category-cart {display:inline-block; width:18.5%; margin:0 0.5% 30px 0.5%; text-align:center; 
		vertical-align:bottom; background:#f5f5f5;
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:48%; }
				@include respond-to(small-tablets) { width:31.5%;}
		a{
			img {max-width:100%; margin-bottom:10px;
				@include respond-to(handhelds) { margin-bottom:10px; }
				}
			h3 { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 18px; text-transform:uppercase; line-height:130%; color:#000000;
			margin-bottom:12px;
				@include respond-to(handhelds) { font-size: 16px; margin-bottom:10px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 16px; }
		}	
			
			} // a
} // .category-cart

} // #category-tab



#otzuvu-baner {  font-family: "SegoeUILight"; text-transform:uppercase;

    min-height: 500px;
    background: transparent;
	
	@include respond-to(handhelds) { min-height: auto;  }
@include respond-to(wide-handhelds) { min-height: 400px;  }
@include respond-to(small-tablets) { min-height: 400px;  }
@include respond-to(tablets) { min-height: 400px;  }
	
	
			@include respond-to(handhelds) { min-height: 500px; }
			@include respond-to(tablets) {  min-height: 400px; }

	.padding {padding-top:60px;}
	
	
	.otzuvu-baner-header {text-align:center; color:#fff; margin-bottom:60px;
	
    text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

	
		h2 { font-size: 48px; margin-bottom:20px; }
		h3 { font-size: 24px; margin-bottom:20px;}
		h4 { font-size: 30px; margin-bottom:20px;}
		
		@include respond-to(handhelds) { 

		h2 { font-size: 36px; margin-bottom:20px; }
		h3 { font-size: 18px; margin-bottom:20px;}
		h4 { font-size: 24px; margin-bottom:20px;}

		}
	
	} //main-baner-header
	
	.owl-otzuvu-cart { text-align:center; 
	font-family: "SegoeUILight"; font-size:18px;  color:#fff;
		@include respond-to(handhelds) { font-size:16px;  }
	
	p {max-width:600px; display:block; margin:0 auto 20px; line-height:130%; padding:0px 10px; text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;}			
	
	}
	
	
	
	.otzuvu-baner-line {text-align:center; margin-top:20px;
		.otzuvu-baner-button {display:inline-block; margin:0px 10px 20px 10px; padding:10px 30px; background: rgba( 71, 52, 33, 0.6); border:1px solid #fff; color:#fff;
		font-family: "SegoeUIRegular";  font-size:20px; 
		
		@include respond-to(handhelds) { font-size:18px;} 
		
			&:hover {background: rgba( 71, 52, 33, 1);}
		}
		
	} //otzuvu-baner-line
} //#otzuvu-baner



  
  
#news {

	.padding {padding-top:20px; padding-bottom:20px;}
	
	.center-line { 
		h2 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; font-weight:normal;
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
		
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
	} //center-line

	.news-cart { padding:0px 5px;  margin: 3px;
	img {max-width:100%; margin-bottom:12px;}
	h3 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase; font-weight:normal; margin-bottom:12px; line-height:130%;
			@include respond-to(handhelds) { font-size:14px;}
			@include respond-to(wide-handhelds) { font-size:16px; }

	}
	}
	p { text-align:center; font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px;  line-height:130%; color:#000000;}
	
	.owl-theme .owl-controls .owl-page span{
	background: #000;
}
	
} // #news


#text {

	.padding {padding-top:20px; padding-bottom:20px;}
	
	.center-line { 
		h1 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; font-weight:normal;
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
		
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
	} //center-line



	// 				OFF \/
	.text-col-line{ padding:0px 10px;
	.text-col-31 {display:inline-block; width:31.5%; margin:0 0.5% 18px 0.5%; 
	text-align:justify ; vertical-align:top;
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:96%; }
				@include respond-to(small-tablets) { width:96%;}
	
	img {max-width:100%; margin-bottom:18px;}
	h2 {font-family: "SegoeUIBold";  font-size:14px; font-weight:normal; margin-bottom:18px; line-height:130%;}
	p {font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal; margin-bottom:16px; line-height:130%;}
	}

	.text-col-47 {display:inline-block; width:47.5%; margin:0 0.5% 18px 0.5%; 
	 vertical-align:top;
				@include respond-to(handhelds) { width:100%; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { width:96%; }
				@include respond-to(small-tablets) { width:96%;}
	
	img {max-width:100%; margin-bottom:18px;}
	h1 {text-align: left; font-family: "SegoeUIBold";  font-size:22px; font-weight:normal; margin-bottom:18px; }
	h2 {font-family: "SegoeUIBold";  font-size:14px; font-weight:normal; margin-bottom:18px; line-height:130%;}
	p, li {font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal; margin-bottom:16px; line-height:130%; text-align:justify;}
	ol, ul {margin-left:20px;
	

	}
	ol li {list-style-type:decimal; text-align:justify;}
	ul li {list-style-type:disc; text-align:justify;}
	}

	}//text-col-line-OFF /\


	.text-col-line-3 { padding:0px 10px;
	
	width:calc(100% - 20px); /*указываем общую ширину блока с колонками*/
text-align:justify; /*выравнивание текста внутри колонок*/
column-count: 3; /*количество колонок, на которое хотим разбить текст*/
-moz-column-count: 3; /*для мозилы*/
-webkit-column-count: 3; /*для webkit браузеров*/
column-gap: 20px; /*отступ между колонками*/
-moz-column-gap: 20px;
-webkit-column-gap: 20px;
/* column-rule: 1px solid #000; если требуется по дизайну, разделяем колонки линией
-moz-column-rule: 1px solid #000;
-webkit-column-rule: 1px solid #000;*/

				@include respond-to(handhelds) { column-count: 1; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { column-count: 1; }
				@include respond-to(small-tablets) { column-count: 2;}
				@include respond-to(tablets) { column-count: 2;}


	img {max-width:100%; margin-bottom:18px;}
	h2 {font-family: "SegoeUIBold";  font-size:14px; font-weight:normal; margin-bottom:18px; line-height:130%;}
	p {font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal; margin-bottom:16px; line-height:130%;}

	}



} // #text

#stati {

	.padding {padding-top:20px; padding-bottom:20px;}
	
	.center-line { 
		h1 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; font-weight:normal;
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
		
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
	} //center-line

	.stati-line-block { width:calc(100% - 20px); margin-bottom: 20px; padding:0px 10px;

	.stati-img-left {width:148px; display: inline-block; vertical-align: top; margin-right: 20px;
						@include respond-to(handhelds) {width:100%; float: none; margin-right:0; }
				@include respond-to(wide-handhelds) { width:100%; float: none; margin-right:0; }

	}	

	.stati-text-right {display: inline-block; vertical-align: top; width:calc(100% - 180px);
					@include respond-to(handhelds) {width:100%; float: none; margin-right:0; }
				@include respond-to(wide-handhelds) { width:100%; float: none; margin-right:0; }

	}
	

	
	a {color: #000; 
		&:hover {text-decoration: underline;}
	}

	img {max-width:100%;}


	h2 {font-family: "SegoeUIBold";  font-size:18px; font-weight:normal; 
		margin-bottom:18px; line-height:130%;
				@include respond-to(handhelds) {margin-bottom:10px; }
				@include respond-to(wide-handhelds) { margin-bottom:10px; }


	}
	p {font-family: "SegoeUIRegular";  font-size:14px; font-weight:normal; margin-bottom:16px; line-height:130%;}

	} // stati-line-block



} // #stati



#kontaktu { @include clearfix;

	.padding {padding-top:20px; padding-bottom:20px;}
	
	.center-line { 
		h1 {text-align:center; font-family: "SegoeUIBold";  font-size:18px; color:#000; 
		text-transform:uppercase;  background: url(../img/line-dotted.png) center left repeat-x;  line-height:20px; margin-bottom:20px; font-weight:normal;
			@include respond-to(handhelds) { font-size:14px; background:none;}
			@include respond-to(wide-handhelds) { font-size:16px; }
		
		
			span {background:#fff; padding:10px 20px 10px 20px;
				@include respond-to(handhelds) { padding:0px; background:none;}	
					
					}
			}
	} //center-line

.map-left {width:65%; margin-right:2%; float:left;

				@include respond-to(handhelds) {width:100%; float: none; margin-right:0; }
				@include respond-to(wide-handhelds) { width:100%; float: none; margin-right:0; }
				@include respond-to(small-tablets) { width:48%; }
				@include respond-to(tablets) { width:48%;}

}
.kontaktu-text-right {width:33%; float:right;

			@include respond-to(handhelds) {width:calc(100% - 20px); float: none; padding:10px; margin-bottom: 10px; }
			@include respond-to(wide-handhelds) { width:calc(100% - 20px); float: none; padding:10px; margin-bottom: 10px; }
			@include respond-to(small-tablets) { width:50%; }
			@include respond-to(tablets) { width:50%; }

}

.kontaktu-text-line { font-family: "SegoeUIRegular";  font-size:14px; color:#171717; margin-bottom:20px;

				@include respond-to(handhelds) {  margin-bottom: 10px;}
				@include respond-to(wide-handhelds) { margin-bottom: 10px; }

	.kontaktu-icon {display: inline-block; vertical-align: bottom; margin-right:10px; width:55px; 
	img {max-width: 100%;}

	}
	.kontaktu-text-info {display: inline-block; vertical-align: top; width:calc(100% - 70px);



	p {line-height: 130%;}
	a {color:#171717; text-decoration: underline; &:hover {text-decoration: none;}}

	&.tel {font-size:24px; vertical-align: top; }
	&.mail {font-size:18px; padding-top: 15px;}




	
	}

}





} // #kontaktu



































