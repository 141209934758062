#breadcrumb {  

	font-family: "SegoeUILight"; font-size:16px;  margin-bottom:20px; line-height:130%;
@include respond-to(handhelds) { margin:0px 0px 10px 0px; font-size:12px;}
@include respond-to(wide-handhelds) { font-size:12px; }
@include respond-to(small-tablets) { font-size:14px; }
@include respond-to(tablets) { font-size:14px; }
@include respond-to(display) {font-size:14px; }

	
	a{color:#2f251a; 
		&:hover {color:#d23600}
	}

	.padding {padding:20px 10px 0px 10px;}
	
	.breadcrumb-back {border:1px solid #e1e1e1; display: inline-block; margin-right:20px;

	@include respond-to(handhelds) { display: block; margin:0px 0px 10px 0px;}
@include respond-to(wide-handhelds) { display: block; margin:0px 0px 10px 0px; }

					a {padding:10px 20px 10px 10px; display: block; text-transform: uppercase; font-size: 14px; color:#333; 

	@include respond-to(handhelds) { font-size: 12px;}
@include respond-to(wide-handhelds) { font-size: 12px; }

						img {margin-right:10px; display: inline-block; vertical-align: top;}
						span {display: inline-block; vertical-align: top;}
					}	
} //.breadcrumb-back

.puti { display: inline-block; margin-top:10px;

	@include respond-to(small-tablets) { display: block; margin-top:10px;}

	font-family: "SegoeUILight"; font-size:16px;  margin-bottom:20px; line-height:130%;
@include respond-to(handhelds) { margin:0px 0px 10px 0px; font-size:12px;}
@include respond-to(wide-handhelds) { font-size:12px; }
@include respond-to(small-tablets) { font-size:14px; }
@include respond-to(tablets) { font-size:14px; }
@include respond-to(display) {font-size:14px; }

	a{color:#2f251a; 
		&:hover {color:#d23600}
	}



}

	
} //#breadcrumb




#category-text { margin-bottom:20px;

.padding {padding:0px 10px 0px 10px;}

h1 {font-family: "SegoeUILight"; font-size:28px; font-weight:normal; text-transform:uppercase; margin-bottom:18px; line-height:130%;
@include respond-to(handhelds) { margin:0px 0px 10px 0px; font-size:16px;}
@include respond-to(wide-handhelds) { font-size:16px; }
@include respond-to(small-tablets) { font-size:18px; }
@include respond-to(tablets) { font-size:20px; }
@include respond-to(display) {font-size:24px; }
}

h2 {font-family: "SegoeUILight"; font-size:18px; font-weight:normal; text-transform:uppercase; margin-bottom:18px; line-height:130%;
@include respond-to(handhelds) { margin:0px 0px 10px 0px; font-size:13px;}
@include respond-to(wide-handhelds) { font-size:14px; }
@include respond-to(small-tablets) { font-size:15px; }
@include respond-to(tablets) { font-size:16px; }
@include respond-to(display) {font-size:17px; }
}


p {font-family: "SegoeUIRegular"; font-size:16px; margin-bottom:18px; line-height:130%;
@include respond-to(handhelds) { margin:0px 0px 8px 0px; font-size:12px;}
@include respond-to(wide-handhelds) { font-size:12px; }
@include respond-to(small-tablets) { font-size:14px; }
@include respond-to(tablets) { font-size:14px; }
@include respond-to(display) {font-size:14px; }
}


a {color:#603426; text-decoration: underline;}

    a:link {}
    a:visited {color:#6E615D;}
    a:hover {text-decoration: none;}
    a:active {color:#937C74;}


.line-premium { @include clearfix;
	.line-premium-img {float:left; width:5%; text-align:center;
	@include respond-to(handhelds) { display:none; }
@include respond-to(wide-handhelds) { display:none; }
@include respond-to(small-tablets) { width:20%; }
@include respond-to(tablets) { width:20%; }
@include respond-to(display) {  width:10%;}

		img {max-width:100%;}
	}
	ul {float:left; width:95%;
	@include respond-to(handhelds) { float:none; }
@include respond-to(wide-handhelds) { float:none;  }
@include respond-to(small-tablets) { width:80%; }
@include respond-to(tablets) { width:80%; }
@include respond-to(display) { width:90%; }
	
	}
	ul li {font-family: "SegoeUIRegular"; font-size:16px; line-height:130%; margin:0px 0px 12px 10px;
	@include respond-to(handhelds) { margin:0px 0px 8px 0px; font-size:12px;}
@include respond-to(wide-handhelds) { font-size:12px; }
@include respond-to(small-tablets) { font-size:14px; }
@include respond-to(tablets) { font-size:14px; }
@include respond-to(display) {font-size:14px; }
	}
} // .line-premium

} // #category-text 


#tovaru { margin-bottom:20px; 

.pod-block {@include respond-to(display) { max-width: 1600px; };}

.padding {padding:0px 10px 0px 10px;}

.tovaru-line {}

.tovaru-cart {display:inline-block; width:20.5%; margin:0 0.5% 24px 0.5%; 
		vertical-align:top; border: 16px solid #f0f0f0; background:#f0f0f0;

					/*делаем карточки серыми при навдении
					@include respond-to(display) { 
					background:#fbfbfb; &:hover {background:#f0f0f0; }
					}*/
		
				@include respond-to(handhelds) { width:94%; margin:0 0 40px 0; border: 8px solid #f0f0f0;}
				@include respond-to(wide-handhelds) { width:44%; border: 4px solid #f0f0f0; }
				@include respond-to(small-tablets) { width:28.5%; border: 8px solid #f0f0f0;}
				@include respond-to(tablets) { width:29.5%; border: 8px solid #f0f0f0;}
				@media only screen and (min-width: 980px) and (max-width: 1200px) { border: 8px solid #f0f0f0; }
		
			.tovaru-cart-img {   margin-bottom:16px; display: block;  
					img {max-width:100%; border-bottom:2px solid #fff;}
				@include respond-to(handhelds) { margin-bottom:12px; }

						.tovaru-cart-img-png {display: none;}
						.tovaru-cart-img-jpg {display: block;}

					/*скрываем jpg, показываем png*/
					@include respond-to(display) { 

						background:#f0f0f0; 
						&:hover {background:#fbfbfb; } 

						.tovaru-cart-img-png {display: block;}
						.tovaru-cart-img-jpg {display: none;}
					}


						
			}

			.pattern-line { margin-top: -26px; margin-bottom: 6px; margin-left:25px;
				.pattern {display: inline-block; width:20px; margin-right: 2px; 

					img {max-width: 100%;}
				}

			}
				
			.tovaru-cart-title { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 14px; color:#0f0f0f; line-height:130%; display:block; padding: 10px; 
				width: calc(100% - 20px); text-align: center; 
				@include respond-to(handhelds) { font-size: 14px; }
				@include respond-to(wide-handhelds) { font-size: 14px; }
				@include respond-to(small-tablets) { font-size: 14px; }
				@include respond-to(tablets) { font-size: 14px; }
				@include respond-to(display) { font-size: 14px; &:hover {background:#fbfbfb;} }	
			
			.net-nalichia {color:#d23600;}
			.est-nalichie {color:#2cb425;}
			.pod-zakaz {color:#033657;}
						
			}

			.cena-line {width:100%; @include clearfix;}

			.cena-old { font-family: "SegoeUIRegular"; font-weight:normal; font-size: 24px; line-height:130%; float:left; width:50%; text-align:center; color:#333; 
			margin-bottom:5px; text-decoration: line-through;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }			
			}


			.cena { font-family: "SegoeUIBold"; font-weight:normal; font-size: 24px; line-height:130%; float:right; width:50%; text-align:center;
			margin-bottom:5px;
				@include respond-to(handhelds) { font-size: 16px; }
				@include respond-to(wide-handhelds) { font-size: 18px; }
				@include respond-to(small-tablets) { font-size: 18px; }
				@include respond-to(tablets) { font-size: 18px; }
				@include respond-to(display) { font-size: 20px; }		

			&.cena-centr {width:100%; float:none;}


			}
			
			
		} // .tovaru-cart
} // #tovaru 